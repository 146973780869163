<template>
  <VueAwesomeSideBar
      v-model:miniMenu="miniMenu"
      v-model:collapsed="collapsed"
      v-model:BottomMiniMenuBtn="bottomMiniMenuBtn"
      v-model:rtl="rtl"
      v-model:menuType="menuType"
      v-model:childrenOpenAnimation="childrenOpenAnimation"
      :closeOnClickOutSide="$store.getters.isMobile ? closeOnClickOutSide : false"
      :overLayerOnOpen="$store.getters.isMobile ? overLayerOnOpen : false"
      :menu="menu"
      vueRouterEnabel
      v-if="$store.getters.getLoggedIn === true"
    ></VueAwesomeSideBar>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'
import { useStore } from "vuex";

const collapsed = ref(false)
const miniMenu = ref(true)
const bottomMiniMenuBtn = ref(true)
const paddingTop = ref('50px')
const rtl = ref(false)
const menuType = ref('fully')
const childrenOpenAnimation = ref(true)
const closeOnClickOutSide = ref(true)
const overLayerOnOpen = ref(true)

const store = useStore();

let records = reactive([])
let recordsReports = reactive([])
let recordsInventory = reactive([])
let recordsRentals = reactive([])
let recordsConference = reactive([])
let recordsInventoryLogistic = reactive([])
let recordsScrums = reactive([])
let recordsManage = reactive([])
let recordsOrders = reactive([])
let recordsStockroom = reactive([])
let recordsLogisticConference = reactive([])
let menu = reactive([
  // {
  //   name: '',
  //   icon: { text: '' , class: 'icomoon icon-navicon' },
  // },
  {
    name: 'Inicio',
    href: '/home',
    icon: { text: '', class: 'siscont icon_home'},
  },
  // {
  //   header: 'Settings'
  // },
  {
    name: 'Cadastro',
    // href: '/order',
    icon: { text: '', class: 'siscont icon_list'},
    children: records
  },
  {
    name: 'Vendas',
    // href: '/order',
    icon: { text: '', class: 'siscont icon_tag'},
    children: recordsOrders
  },
  {
    name: 'Inventário',
    // href: '/inventory',
    icon: { text: '', class: 'siscont icon_inventory'},
    children: recordsInventory
  },
  {
    name: 'Conferência',
    // href: '/conference',
    icon: { text: '', class: 'siscont icon_scan'},
    children: recordsConference
  },
])

if (store.getters.checkPermissionsUser('list-permissions')){
    menu.push(
      {
        name: 'Gerência',
        // href: '/rental',
        icon: { text: '', class: 'siscont icon_manage'},
        children: recordsManage
      },
    )
}

if (store.getters.checkPermissionsUser('list-wallpapers')){
    menu.push(
      {
        name: 'Wallpaper',
        href: '/wallpapers',
        icon: { text: '', class: 'siscont icon_dataset'},
      },
    )
}

if (
  store.getters.checkPermissionsUser('list-location-products') ||
  store.getters.checkPermissionsUser('list-locations') ||
  store.getters.checkPermissionsUser('list-storages') ||
  store.getters.checkPermissionsUser('see-logistic-inventory-logs')
) {
  menu.push(
    {
      name: 'Gestão Pulmão',
      icon: { text: '', class: 'siscont icon_warehouse' },
      children: recordsInventoryLogistic
    },
  )
}

if (store.getters.checkPermissionsUser('list-service-orders')) {
  let serviceOrderMenu = {
    name: 'Ordens de serviço',
    icon: { text: '', class: 'siscont icon_service_order' },
    children: [
      {
        name: 'OS',
        href: '/service-order',
      },
      {
        name: 'Equipamentos',
        href: '/service-order-equipment',
      }
    ]
  };

  // Condicionalmente adiciona um item ao menu
  if (store.getters.checkPermissionsUser('list-typeServiceOrders')) {
    serviceOrderMenu.children.push({
      name: 'Tipos de OS',
      href: '/type-service-order',
    });
  }

  serviceOrderMenu.children.push({
      name: 'Serviços de OS',
      href: '/service-order/services',
    });

    serviceOrderMenu.children.push({
      name: 'Pedidos de OS',
      href: '/service-order/requests',
    });

  // Adiciona o menu completo ao array principal
  menu.push(serviceOrderMenu);
}


if (store.getters.checkPermissionsUser('list-rentals')){
    menu.push(
      {
        name: 'Locações',
        href: '/rental',
        icon: { text: '', class: 'siscont icon_rental'},
        children: recordsRentals
      },
    )
}

if (store.getters.checkPermissionsUser('list-cashier')) {
  menu.push(
    {
      name: 'Caixas',
      href: '/cashier',
      icon: { text: '', class: 'siscont icon_computer' },
    }
  )
}

if (store.getters.checkPermissionsUser('list-all-stockrooms')){
    menu.push(
      {
        name: 'Solicitação de Compra',
        // href: '/stockroom',
        icon: { text: '', class: 'siscont icon_warehouse_alternative'},
        children: recordsStockroom
      },
    )
}

if (store.getters.checkPermissionsUser('list-order-budgets')){
    menu.push(
      {
        name: 'Orçamento',
        href: '/budget',
        icon: { text: '', class: 'siscont icon_budget'},
        // children: recordsStockroom
      },
    )
}
// list-reports
if (store.getters.checkPermissionsUser('report-orders')){
    menu.push(
      {
        name: 'Relatórios',
        // href: '/report',
        icon: { text: '', class: 'siscont icon_report'},
        children: recordsReports
      }
    )
}

if (store.getters.checkPermissionsUser('list-scrums')){
  menu.push(
    {
      name: 'Bitrix',
      // href: '/scrum',
      icon: { text: '', class: 'siscont icon_list'},
      children: recordsScrums
    }
  )
}

const loadMenu = async () => {
  records.length = 0
  recordsReports.length = 0
  recordsInventory.length = 0
  recordsRentals.length = 0
  recordsScrums.length = 0

  if (recordsOrders.find(e => e?.href === '/order') == undefined) {
    recordsOrders.push(
      {
        name: 'Pedidos',
        href: '/order',
        // icon: { text: '', class: 'siscont icon_person'},
      }
    )
  }

  if (records.find(e => e?.href === '/customer') == undefined) {
    records.push(
      {
        name: 'Cliente',
        href: '/customer',
        // icon: { text: '', class: 'siscont icon_person'},
      }
    )
  }

  if (records.find(e => e?.href === '/product/groups') == undefined) {
    records.push(
      {
        name: 'Grupo de Produtos',
        href: '/product/groups',
        // icon: { text: '', class: 'siscont icon_person'},
      }
    )
  }

  if (records.find(e => e?.href === '/product') == undefined) {
    records.push(
      {
        name: 'Produto',
        href: '/product',
        // icon: { text: '', class: 'siscont icon_cart'},
      }
    )
  }

  if (store.getters.checkPermissionsUser('list-location-products')){
    if (recordsInventoryLogistic.find(e => e?.href === '/type-products-logistic') == undefined) {
      recordsInventoryLogistic.push(
        {
          name: 'Gerência Armazenamento',
          href: '/type-products-logistic',
          // icon: { text: '', class: 'siscont icon_cart'},
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-locations')){
    if (recordsInventoryLogistic.find(e => e?.href === '/type-adress') == undefined) {
      recordsInventoryLogistic.push(
        {
          name: 'Endereçamento',
          href: '/type-adress',
          // icon: { text: '', class: 'siscont icon_cart'},
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-storages')){
    if (recordsInventoryLogistic.find(e => e?.href === '/type-storage') == undefined) {
      recordsInventoryLogistic.push(
        {
          name: 'Armazenamento',
          href: '/type-storage',
          // icon: { text: '', class: 'siscont icon_cart'},
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('see-logistic-inventory-logs')) {
    if (recordsInventoryLogistic.find(e => e?.href === '/type-products-logistic/logs') == undefined) {
      recordsInventoryLogistic.push(
        {
          name: 'Logs',
          href: '/type-products-logistic/logs',
          // icon: { text: '', class: 'siscont icon_cart'},
        }
      )
    }
  }

  // if (records.find(e => e?.href === '/prescriber') == undefined) {
  //   records.push(
  //     {
  //       name: 'Prescritor',
  //       href: '/prescriber',
  //       // icon: { text: '', class: 'siscont icon_cart'},
  //     }
  //   )
  // }

  if (store.getters.checkPermissionsUser('list-users')){
    if (!recordsManage.find(e => e.href === '/user')) {
      recordsManage.push(
        {
          name: 'Usuários',
          href: '/user',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-permissions')){
    if (!recordsManage.find(e => e.href === '/permission')) {
      recordsManage.push(
        {
          name: 'Permissões de acesso',
          href: '/permission',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-roles')){
    if (!recordsManage.find(e => e.href === '/role')) {
      recordsManage.push(
        {
          name: 'Grupos de acesso',
          href: '/role',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-enterprises')){
    if (!records.find(e => e.href === '/enterprise')) {
      records.push(
        {
          name: 'Empresas',
          href: '/enterprise',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-typeOrders')){
    if (!recordsOrders.find(e => e.href === '/type-order')) {
      recordsOrders.push(
        {
          name: 'Tipos de pedido',
          href: '/type-order',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (!records.find(e => e.href === '/service')) {
    records.push(
      {
        name: 'Serviços',
        href: '/service',
        // icon: { text: '', class: 'siscont icon_cart'},})
      }
    )
  }

    if (store.getters.checkPermissionsUser('list-typeRentals')){
    if (!recordsRentals.find(e => e.href === '/type-rental')) {
      recordsRentals.push(
        {
          name: 'Tipos de locação',
          href: '/type-rental',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-payments')){
    if (!records.find(e => e.href === '/payment')) {
      records.push(
        {
          name: 'Forma de pagamento',
          href: '/payment',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-deadlines')){
    if (!recordsRentals.find(e => e.href === '/deadline')) {
      recordsRentals.push(
        {
          name: 'Planos',
          href: '/deadline',
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('create-goals')){
    if (!records.find(e => e.href === '/goal')) {
      records.push(
        {
          name: 'Metas',
          href: '/goal',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-scrums')){
    if (!recordsScrums.find(e => e.href === '/scrums')) {
      recordsScrums.push(
        {
          name: 'Scrums',
          href: '/scrum',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-ftes')){
    if (!recordsScrums.find(e => e.href === '/fte')) {
      recordsScrums.push(
        {
          name: 'FTE',
          href: '/fte',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('report-orders')){
    if (!recordsReports.find(e => e.href === '/report/orders')) {
      recordsReports.push(
        {
          name: 'Vendas',
          href: '/report/orders',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('report-rentals')){
    if (!recordsReports.find(e => e.href === '/report/rentals')) {
      recordsReports.push(
        {
          name: 'Locações',
          href: '/report/rentals',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (!recordsReports.find(e => e.href === '/report/products')) {
    recordsReports.push(
      {
        name: 'Produtos',
        href: '/report/products',
        // icon: { text: '', class: 'siscont icon_cart'},})
      }
    )
  }
  
  if (store.getters.checkPermissionsUser('report-rentals')) {
    if (!recordsReports.find(e => e.href === '/report/rental/prescriber')) {
      recordsReports.push(
        {
          name: 'Locação por Prescritor',
          href: '/report/rental/prescriber',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('performance-report-logistic')) {
    if (!recordsReports.find(e => e.href === '/report/logistic/performance')) {
      recordsReports.push(
        {
          name: 'Performance da Logística',
          href: '/report/logistic/performance',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-report-quarter')) {
    if (!recordsReports.find(e => e.href === '/report/inventory')) {
      recordsReports.push(
        {
          name: 'Relatório de Divergências',
          href: '/report/inventory',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (recordsInventory.find(e => e?.href === '/inventory') == undefined) {
    recordsInventory.push(
      {
        name: 'Loja',
        href: '/inventory',
        // icon: { text: '', class: 'siscont icon_cart'},
      }
    )
  }

  if (store.getters.checkPermissionsUser('inventory-logistic')){
    if (!recordsInventory.find(e => e.href === '/inventory/logistic')) {
      recordsInventory.push(
        {
          name: 'Logística',
          href: '/inventory/logistic',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }
  
  if(store.getters.checkPermissionsUser('list-single-counts')){
    if (!recordsInventory.find(e => e.href === '/inventory/single-count')) {
      recordsInventory.push(
        {
          name: 'Contagem Avulsa',
          href: '/inventory/single-count',
        }
      )
    }
  }

  if(store.getters.checkPermissionsUser('view-tech-assist-inventorys-result')){
    if (!recordsInventory.find(e => e.href === '/inventory/tech-assist')) {
      recordsInventory.push(
        {
          name: 'Assistência Técnica',
          href: '/inventory/tech-assist',
        }
      )
    }
  }

  if (!recordsRentals.find(e => e.href === '/rental')) {
    recordsRentals.push(
      {
        name: 'Locações',
        href: '/rental',
        // icon: { text: '', class: 'siscont icon_cart'},})
      }
    )
  }

  if (!recordsRentals.find(e => e.href === '/rental-stock')) {
    recordsRentals.push(
      {
        name: 'Estoque',
        href: '/rental-stock',
        // icon: { text: '', class: 'siscont icon_cart'},})
      }
    )
  }

  if (store.getters.checkPermissionsUser('list-equipment')) {
    if (!recordsRentals.find(e => e.href === '/report/orders')) {
      recordsRentals.push(
        {
          name: 'Equipamentos',
          href: '/equipment',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (!recordsConference.find(e => e.href === '/conference')) {
    recordsConference.push(
      {
        name: 'Loja',
        href: '/conference',
        // icon: { text: '', class: 'siscont icon_cart'},})
      }
    )
  }

  if (store.getters.checkPermissionsUser('all-logistic-conferences')) {
    if (!recordsConference.find(e => e.href === '/conference/logistic')) {
      recordsConference.push(
        {
          name: 'Importação',
          // href: '/conference/logistic',
          children: recordsLogisticConference
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('all-logistic-conferences')) {
    if (!recordsLogisticConference.find(e => e.href === '/spreadsheet')) {
      recordsLogisticConference.push(
        {
          name: 'Planilhas',
          href: '/spreadsheet',
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('all-logistic-conferences')) {
    if (!recordsLogisticConference.find(e => e.href === '/conference/logistic')) {
      recordsLogisticConference.push(
        {
          name: 'Listagem',
          href: '/conference/logistic',
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-entrance-conferences')) {
    if (!recordsConference.find(e => e.href === '/conference/entrance')) {
      recordsConference.push(
        {
          name: 'Entrada',
          href: '/conference/entrance'
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('scrum-metrics')){
    if (!recordsScrums.find(e => e.href === '/report/scrums')) {
      recordsScrums.push(
        {
          name: 'Relatórios',
          href: '/report/scrums',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-control-hours')) {
    if (!recordsRentals.find(e => e.href === '/rental/control-hours')) {
      recordsRentals.push({
        name: 'Controle de Horas',
        href: '/rental/control-hours'
      });
    }
  }

  if (store.getters.checkPermissionsUser('list-all-stockrooms')){
    if (!recordsStockroom.find(e => e.href === '/stockroom')) {
      recordsStockroom.push(
        {
          name: 'Solicitações',
          href: '/stockroom',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }

  if (store.getters.checkPermissionsUser('list-all-stockrooms')){
    if (!recordsStockroom.find(e => e.href === '/stockroom/products')) {
      recordsStockroom.push(
        {
          name: 'Produtos',
          href: '/stockroom/products',
          // icon: { text: '', class: 'siscont icon_cart'},})
        }
      )
    }
  }
};

watch(() => store.getters.getUser, function() {
    loadMenu()
  }, { deep: true, immediate: true });
</script>