import { createStore } from 'vuex'
import { getStorage, saveStorage, clearStorage } from '@/assets/js/storage'
import { encryptId, decryptId } from '@/assets/js/crypter'
import { copyClipboard, copyClipboardByModal } from '@/assets/js/copyClipboard'
import type { Item, ServerOptions } from "vue3-easy-data-table";
import router from '../router'
import { Modal } from 'bootstrap';

export default createStore({
  state: {
    user: {
      name: '',
      email: '',
      permissions: []
    },
    soundPath: '/conference-sound/conference-sound.mp3',
    customConfirm: null,
    message: '',
    messageType: '',
    redirectRoute: null,
    modalHistory: [],
    modalWithdraw: false,
    modalReinforcement: false,
    modalCashier: false,
    modalWithDrawMoney: false,
    reloadOnModalClose: false,
    expiresIn: null,
    token: null,
    loggedIn: false,
    allStatesAddress: [],
    enterprises: [],
    enterprise: { id: null },
    enterpriseId: null,
    typeOrders: [],
    typeServiceOrders: [],
    typeServiceOrderId:null,
    typeServiceOrder: { id: null, use_enterprise_default: 0, enterprise_id: 0, payments: [] },
    typeOrder: { id: null, use_enterprise_default: 0, enterprise_id: 0, payments: [] },
    typeOrderId: null,
    typeRentals: [],
    typeRentalId: null,
    typeRental: { id: null, use_enterprise_default: 0, enterprise_id: 0, payments: [] },
    payments: [],
    areContentsVisible: false,
    paymentsServiceOrder: [],
    //eslint-disable-next-line
    regexPhone: /(\(\d{2}\)\s)(\d{4,5}\-\d{4})/,
    //eslint-disable-next-line
    regexDocument: /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/,
    msgRequired: 'Campo obrigatório',
    msgFieldInvalid: 'Campo inválido',
    msgPhoneInvalid: 'Telefone inválido',
    msgDocumentInvalid: 'Documento inválido',
    msgEmaiInvalid: 'E-mail inválido',
    msgZipcodeInvalid: 'CEP inválido',
    optionsSearchFieldProduct: [
      { 'value': 'name', 'label': 'Nome' },
      { 'value': 'sku', 'label': 'SKU' },
      { 'value': 'ref_site', 'label': 'Ref. Site' },
      { 'value': 'sankhya_id', 'label': 'Cod. Sankhya' },
      { 'value': 'barcode', 'label': 'Codigo de Barras' },
    ],
    optionsSearchFieldCustomer: [
      { 'value': 'name', 'label': 'Nome' },
      { 'value': 'email', 'label': 'E-mail' },
      { 'value': 'document', 'label': 'Documento' },
      { 'value': 'sankhya_id', 'label': 'Cod. Sankhya' },
    ],
    order: {
      customer: {
        id: null,
        sankhya_id: null,
        name: '',
        email: '',
        document: '',
        phone: '',
        type: 'F',
        zip_code: '',
        address: '',
        number_address: '',
        district: '',
        city: '',
        uf: '',
        state: '',
        complement_address: '',
        ibge: '',
        ie: '',
        is_dealer: false,
        is_prescribers: null,
      },
      prescriber_id: null,
      payments: [],
      products: [],
      total_amount: 0,
      subtotal: 0,
      shipping_amount: 0,
      shipping_cost: 0,
      discount_amount: 0,
      seller_id: 0,
      created_at: 0,
      obs: null,
      obsInternal: null,
      is_delivery: false,
      shipping_method: null,
      shipping_estimate_days: null,
      intelipost_response: null,
      indication: null,
      service_order_equipment_id: null,
      service_order_budget_id: null,
      rental_id: null
    },
    budget: {
      customer: {
        id: null,
        sankhya_id: null,
        name: '',
        email: '',
        document: '',
        phone: '',
        type: 'F',
        zip_code: '',
        address: '',
        number_address: '',
        district: '',
        city: '',
        uf: '',
        state: '',
        complement_address: '',
        ibge: '',
        ie: '',
        is_dealer: false,
        is_prescribers: null,
      },
      prescriber_id: null,
      payments: [],
      products: [],
      total_amount: 0,
      subtotal: 0,
      shipping_amount: 0,
      shipping_cost: 0,
      discount_amount: 0,
      created_at: 0,
      is_delivery: false,
      shipping_method: null,
      shipping_estimate_days: null,
      intelipost_response: null,
      indication: null,
    },
    rental: {
      customer: {
        id: null,
        sankhya_id: null,
        name: '',
        email: '',
        document: '',
        phone: '',
        type: 'F',
        zip_code: '',
        address: '',
        number_address: '',
        district: '',
        city: '',
        uf: '',
        state: '',
        complement_address: '',
        ibge: '',
        ie: '',
        is_prescribers: null,
      },
      prescriber_id: null,
      payments: [],
      products: [],
      total_amount: 0,
      subtotal: 0,
      shipping_amount: 0,
      shipping_cost: 0,
      discount_amount: 0,
      seller_id: 0,
      created_at: 0,
      obs: null,
      indication:null,
      is_delivery: false,
      shipping_method: null,
      shipping_estimate_days: null,
      intelipost_response: null
    },
    prescriber:{
      id:null,
      name:null
    },
    optionsFilterDateScrums: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    serverOptionsOrders: <ServerOptions>({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      sortType: 'desc',
    }),
    serverItemsLengthOrders: 0,
    itemsOrders: <Item[]>[],
    itemsServiceOrders: <Item[]>[],
    optionsFilterDateOrders: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    optionsFilterServiceOrders: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    filterDateOrders: '',
    filterDateServiceOrders: '',
    formFilterDateOrders: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    formFilterDateServiceOrders: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    formExchangeDateOrders: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateOrders: null,
    endDateOrders: null,
    startDateServiceOrders: null,
    endDateServiceOrders: null,
    formFilterOrders: {
      seller: [],
      status: [],
      type: [],
      enterprise: []
    },
    formFilterServiceOrders: {
      seller: [],
      status: [],
      type: [],
    },
    filterSellerOrders: '',
    filterStatusOrders: '',
    filterTypeOrders: '',
    filterEnterpriseOrder: '',
    filterSellerServiceOrders: '',
    filterStatusServiceOrders: '',
    filterTypeServiceOrders: '',
    showCleanFiltersOrders: false,
    showCleanFiltersServiceOrders: false,
    showCleanFilterOrdersWithheld: false,
    serverOptionsRentals: <ServerOptions>({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      sortType: 'desc',
    }),
    serverItemsLengthRentals: 0,
    itemsRentals: <Item[]>[],
    optionsFilterDateRentals: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    filterDateRentals: '',
    formFilterDateRentals: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateRentals: null,
    endDateRentals: null,
    formFilterRentals: {
      seller: [],
      status: [],
      type: [],
      enterprise:[]
    },
    filterSellerRentals: '',
    filterStatusRentals: '',
    filterTypeRentals: '',
    filterEnterpriseRentals:'',
    startDateTypeProductLogistic: null,
    endDateTypeProductLogistic: null,
    filterDateTypeProductLogistic: '',
    optionsFilterDateTypeProductLogistic: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    formFilterDateTypeProductLogistic: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    optionsFilterDateRentalControlHours: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    startDateRentalControlHoursLogs: null,
    endDateRentalControlHoursLogs: null,
    filterDateRentalControlHours: '',
    formFilterDateControlHoursLogs: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    optionsFilterDateWarehouse: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    filterDateWarehouse: '',
    formFilterDateWarehouse: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateWarehouse: null,
    endDateWarehouse: null,
    serverOptionsWarehouse: <ServerOptions>({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      sortType: 'desc',
    }),
    filterUserWarehouse: '',
    filterStatusWarehouse: ['A','EA'],
    filterEnterpriseWarehouse:'',
    formFilterWarehouse: {
      user: [],
      status: ['A','EA'],
      enterprise:[]
    },
    serverItemsLengthWarehouse: 0,
    itemsWarehouse: <Item[]>[],
    optionsFilterDateLogisticPerformance: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    showCleanFilterRentalControlHours: false,
    showCleanFilterTypeProductLogistic: false,
    showCleanFiltersRentals: false,
    optionsFilterDateCashier: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    showCleanFiltersCashierLogs: false,
    optionsFilterDateCashierLogs: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    formFilterDateCashierLogs: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateCashierLogs: null,
    endDateCashierLogs: null,
    filterDateCashierLogs: '',
    formFilterCashierLogs: {
      enterprise: []
    },
    filterEnterpriseCashierLogs: '',
    optionsFilterDateBudget: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    formFilterDateBudget: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateBudget: null,
    endDateBudget: null,
    filterDateBudget: '',
    serverOptionsBudget: <ServerOptions>({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      sortType: 'desc',
    }),
    serverItemsLengthBudget: 0,
    itemsBudget: <Item[]>[],
    formFilterBudget:{
      status:[]
    },
    filterBudgetStatus:'',
    showCleanFilterBudget:false,
    showCleanFilterWarehouse: true,
    spotDiscountAmount: 0,
    statusOrder: [
      { value: 1, label: "Aguardando Pagamento" },
      { value: 2, label: "Aguardando Faturamento" },
      { value: 3, label: "Aguardando Liberação" },
      { value: 4, label: "Aguardando Confirmação de Pagamento" },
      { value: 7, label: "Aguardando Postagem" },
      { value: 8, label: "Aguardando Entrega" },
      { value: 5, label: "Entregue" },
      { value: 6, label: "Cancelado" },
      { value: 9, label: "Estorno" },
      { value: 10, label: "Aguardando Confirmação" },
      { value: 11, label: "Reprovado" },
      { value: 12, label: "Aguardando Cŕedito" },
    ],
    statusServiceOrder: [
      { value: 1, label: "Em Aberto" },
      { value: 2, label: "Cancelado" },
      { value: 3, label: "Finalizado" },
      { value: 4, label: "Agd. Recebimento" },
      { value: 5, label: "Em Andamento" },
      { value: 6, label: "Agd. Vinculo NF p/ PJ" },
    ],
    statusServiceOrderOpen: [
      { value: 1, label: "Em Aberto" },
      { value: 4, label: "Agd. Recebimento" },
      { value: 5, label: "Em Andamento" },
      { value: 6, label: "Agd. Vinculo NF p/ PJ" },
    ],
    statusOrderOpen: [
      { value: 1, label: "Aguardando Pagamento" },
      { value: 2, label: "Aguardando Faturamento" },
      { value: 3, label: "Aguardando Liberação" },
      { value: 4, label: "Aguardando Confirmação de Pagamento" },
      { value: 10, label: "Aguardando Confirmação" },
    ],
    statusScrumsOpen: [
      { value: 2, label: "Pendente" },
      { value: 3, label: "Em progresso" },
      { value: 4, label: "Supostamente completo" },
      { value: 6, label: "Adiado" },
    ],
    statusScrums: [
      { value: 2, label: "Pendente" },
      { value: 3, label: "Em progresso" },
      { value: 4, label: "Supostamente completo" },
      { value: 5, label: "Completo" },
      { value: 6, label: "Adiado" },
    ],
    sellersFilter: [],
    serverOptionsEquipments: <ServerOptions>({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      sortType: 'desc',
    }),
    serverItemsLengthEquipments: 0,
    serverItemsLengthServiceOrder: 0,
    itemsEquipments: <Item[]>[],
    filterStatusEquipments: '',
    filtersIsAvailableEquipments: null,
    showCleanFiltersEquipments: false,
    formFilterEquipments: {
      status: [],
      isAvailable: null
    },
    statusEquipment: [
      { value: 1, label: "Ativo" },
      { value: 2, label: "Em Análise" },
      { value: 3, label: "Desativado" },
    ],
    installmentPaymentAllowed: [
      'maxipago_link',
      'billet_invoiced'
    ],
    paymentsCodesCash: [
      'cash',
      'cash_not'
    ],
    paymentsCodesPix:[
      'pix_gateway',
      'pix_gateway_not',
      'pix_bb',
      'pix_bb_not'
    ],
    paymentsCodesWireTransfer: [
      'wire_transfer',
      'wire_transfer_not'
    ],
    paymentsCodesBillet: [
      'billet_bb',
      'billet_bb_not',
      'billet_gateway',
      'billet_gateway_not'
    ],
    paymentsNotSendAcquirer: [
      'cash',
      'cash_not',
      'billet_invoiced',
      'wire_transfer',
      'wire_transfer_not',
      'voucher'
    ],
    paymentsAllowedManualApproval: [
      'wire_transfer',
      'wire_transfer_not',
      'pix_bb',
      'pix_bb_not',
      'pix_gateway',
      'pix_gateway_not',
      'cielo_lio',
      'voucher',
      'billet_bb',
      'billet_bb_not',
      'billet_gateway',
      'billet_gateway_not',
      'maxipago_link'
    ],
    paymentsCodesBilletGateway: [
      'billet_gateway',
      'billet_gateway_not'
    ],
    encryptId: encryptId,
    decryptId: decryptId,
    copyClipboard: copyClipboard,
    copyClipboardByModal: copyClipboardByModal,
    infoWireTransfer: "BANCO DO BRASIL (001) \nAgência: 9792-6 \nConta: 125-2 \nCNPJ: 10.851.899/0001-00 \nFavorecido: CPAPS IMPORTAÇÃO E COMÉRCIO DE EQUIPAMENTOS MÉDICOS LTDA",
    monthsYear: [
      { value: 1, label: 'Janeiro' },
      { value: 2, label: 'Fevereiro' },
      { value: 3, label: 'Março' },
      { value: 4, label: 'Abril' },
      { value: 5, label: 'Maio' },
      { value: 6, label: 'Junho' },
      { value: 7, label: 'Julho' },
      { value: 8, label: 'Agosto' },
      { value: 9, label: 'Setembro' },
      { value: 10, label: 'Outubro' },
      { value: 11, label: 'Novembro' },
      { value: 12, label: 'Dezembro' },
    ],
    deadlines: [],
    statusRental: [
      {value: 1, label: "Aguardando Envio Contrato"},
      {value: 2, label: "Aguardando Ass. Contrato"},
      {value: 3, label: "Aguardando Pagamento"},
      {value: 4, label: "Aguardando Faturamento"},
      {value: 5, label: "Aguardando Retorno"},
      {value: 6, label: "Ativo"},
      {value: 7, label: "Finalizado"},
      {value: 8, label: "Cancelado"},
      {value: 9, label: "Aguardando Liberação"},
      {value: 10, label: "Agd. Confirmação Cadastro"},
      {value: 11, label: "Agd. Conf. Troca Plano"},
      {value: 12, label: "Inadimplente"},
      {value: 13, label: "Agd. Conf. Troca Aparelho"},
    ],
    statusOpenRental: [
      {value: 1, label: "Aguardando Envio Contrato"},
      {value: 2, label: "Aguardando Ass. Contrato"},
      {value: 3, label: "Aguardando Pagamento"},
      {value: 4, label: "Aguardando Faturamento"},
      {value: 5, label: "Aguardando Retorno"},
      {value: 9, label: "Aguardando Liberação"},
      {value: 10, label: "Agd. Confirmação Cadastro"},
      {value: 11, label: "Agd. Conf. Troca Plano"},
      {value: 13, label: "Agd. Conf. Troca Aparelho"},
    ],
    indications: [
      { value: null, label: 'Selecione' },
      { value: 1, label: 'Médico/Fisio' },
      { value: 2, label: 'Pesquisa no Google' },
      { value: 3, label: 'Indicação de amigo' },
      { value: 4, label: 'Anúncio Facebook/Instagram/outros sites' },
      { value: 5, label: 'Já é cliente' },
      { value: 7, label: 'Midia Offline' },
      { value: 6, label: 'Não respondeu' },
    ],
    optionsTypesInventory: [
      { value: '', label: 'Selecione' },
      { value: 1, label: 'Completo' },
      { value: 2, label: 'Parcial' },
      { value: 3, label: 'Recontagem' },
    ],
    statusInventory: [
      { value: '', label: 'Selecione' },
      { value: 1, label: 'Aberto' },
      { value: 2, label: 'Em execução' },
      { value: 6, label: 'Agd. análise' },
      { value: 3, label: 'Em análise' },
      { value: 4, label: 'Finalizado' },
      { value: 5, label: 'Cancelado' },
    ],
    optionsLocaleInventory: [
      { value: '', label: 'Selecione' },
      { value: 1, label: 'Venda' },
      { value: 2, label: 'Locação' },
      { value: 3, label: 'Outros' },
    ],
    statusInventoryItem: [
      { value: 1, label: 'Ok' },
      { value: 2, label: 'Divergente' },
    ],
    statusInventoryResumeItem: [
      { value: 1, label: 'Ok' },
      { value: 2, label: 'Divergente (-)' },
      { value: 3, label: 'Divergente (+)' },
    ],
    statusInventoryOpen: [
      { value: 1, label: 'Aberto' },
      { value: 2, label: 'Em execução' },
    ],
    statusSingleCount: [
      { value: 1, label: 'Aberto' },
      { value: 2, label: 'Em execução' },
      { value: 3, label: 'Finalizado' }
    ],
    statusSingleCountOpen: [
      { value: 1, label: "Aberto" },
      { value: 2, label: "Em execução" },
    ],
    optionsFilterDateSingleCount: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    enterprisesLogisticConference: [
        { value: 1, label: 'Matriz' },
        { value: 3, label: 'Atacadista' },
    ],
    showCleanFiltersSingleCount: false,
    formFilterSingleCount: {
      status: [],
      seller: []
    },
    filterStatusSingleCount: '',
    filterSellerSingleCount: '',
    filterDateSingleCount: '',
    filterDateInventorys: '',
    startDateSingleCount: null,
    endDateSingleCount: null,
    formFilterDateSingleCount: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    formFilterDateInventorys: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateInventorys: null,
    endDateInventorys: null,
    formFilterInventorys: {
      enterprise: [],
      status: [],
      type: [],
      locale: []
    },
    filterStatusInventorys: '',
    filterTypeInventorys: '',
    filterEnterpriseInventorys: '',
    filterLocaleInventorys: '',
    showCleanFiltersInventorys: false,
    optionsFilterDateTechAssistInventory: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    showCleanFiltersInventoryTechAssist:false,
    filterDateInventoryTechAssist:'',
    filterStatusInventoryTechAssist:'',
    filterTypeInventoryTechAssist:'',
    startDateInventoryTechAssist:null,
    endDateInventoryTechAssist:null,
    formFilterDateInventoryTechAssist: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    formFilterInventoryTechAssist: {
      status: [],
      type: [],
    },
    statusInventoryTechAssist:[
      {value: 1, label: 'Em Aberto'}, 
      {value: 2, label: 'Em Execução'},
      {value: 3, label: 'Em análise'},
      {value: 4, label: 'Finalizado'},
      {value: 5, label: 'Cancelado'},
      {value: 6, label: 'Agd. análise'},
    ],
    statusInventoryTechAssistOpen:[
      {value: 1, label: 'Em Aberto'},  
      {value: 2, label: 'Em Execução'},
      {value: 3, label: 'Em análise'},
      {value: 5, label: 'Agd. análise'},
    ],
    TypeInventoryTechAssist:[
      {value: 2, label: 'Imobilizado'}, 
      {value: 1, label: 'Terceiro'},
      // {value: 3, label: 'Matriz'}
    ],
    optionsTypesConference: [
      { value: '', label: 'Selecione' },
      { value: 1, label: 'Normal' },
      { value: 2, label: 'Recontagem' },
    ],
    statusConference: [
      { value: '', label: 'Selecione' },
      { value: 1, label: 'Aberto' },
      { value: 2, label: 'Em execução' },
      { value: 6, label: 'Agd. análise' },
      { value: 3, label: 'Em análise' },
      { value: 4, label: 'Finalizado' },
      { value: 5, label: 'Cancelado' },
      { value: 7, label: 'Fin. Diver. Nota' },
      { value: 8, label: 'Fin. Diver. Bipagem' },
      { value: 9, label: 'Agd. Transferência' },
      { value: 10, label: 'Agd. Recontagem' },
    ],
    statusConferenceLogistic: [
      { value: '', label: 'Selecione' },
      { value: 1, label: 'Aberto' },
      { value: 2, label: 'Em Progresso' },
      { value: 3, label: 'Agd. Criação Pedido' },
      { value: 4, label: 'Criando pedido' },
      { value: 5, label: 'Finalizado' },
      { value: 6, label: 'Agd. Finalização' },
      { value: 7, label: 'Cancelado' },
      { value: 8, label: 'Falha Criação Pedido' },
      { value: 9, label: 'Em Divergência' },
      { value: 10, label: 'Finalizado com divergência' },
    ],
    statusConferenceItem: [
      { value: 1, label: 'Ok' },
      { value: 2, label: 'Divergente' },
    ],
    statusConferenceOpen: [
      { value: 1, label: 'Aberto' },
      { value: 2, label: 'Em Progresso' },
    ],
    statusConferenceEntrance: [
      {value: 1 , label: 'Em Conferência'},
      {value: 2 , label: 'Em divergência'},
      {value: 3 , label: 'Finalizado'},
      {value: 4 , label: 'Finalizado com divergência'},
    ],
    statusConferenceEntranceOpen: [
      {value: 1 , label: 'Em Conferência'},
      {value: 2 , label: 'Em divergência'},
    ],
    filterDateConferences: '',
    formFilterDateConferences: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateConferences: null,
    endDateConferences: null,
    formFilterConferences: {
      enterprise: [],
      status: [],
      type: [],
      locale: []
    },
    filterStatusConferences: '',
    filterTypeConferences: '',
    filterEnterpriseConferences: '',
    filterLocaleConferences: '',
    showCleanFiltersConferences: false,
    formFilterDateEntranceConference: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateEntranceConference:null,
    endDateEntranceConference:null,
    filterDateEntranceConferences:'',
    formFilterEntranceConference: {
      status: []
    },
    filterStatusEntranceConference: '',
    optionsFilterDateEntranceConferences: [
      {'value': '', 'label': 'Selecione'},
      {'value': 'now', 'label': 'Hoje'},
      {'value': 'yesterday', 'label': 'Ontem'},
      {'value': 'current_month', 'label': 'Mês atual'},
      {'value': 'last_7_days', 'label': 'Últimos 7 dias'},
      {'value': 'last_15_days', 'label': 'Últimos 15 dias'},
      {'value': 'last_30_days', 'label': 'Últimos 30 dias'},
      {'value': 'last_60_days', 'label': 'Últimos 60 dias'},
      {'value': 'last_90_days', 'label': 'Últimos 90 dias'},
      {'value': 'custom', 'label': 'Personalizado'},
    ],
    showCleanFiltersEntranceConference:false,
    statusInventoryLogistic: [
      { value: 1, label: 'Aberto' },
      { value: 2, label: 'Finalizado' },
      { value: 3, label: 'Cancelado' },
    ],
    statusInventoryProduct: [
      { value: 1, label: 'Pendente' },
      { value: 2, label: 'Em Progresso' },
      { value: 3, label: 'Finalizado' },
      { value: 4, label: 'Cancelado' },
      { value: 5, label: 'Em Análise' },
      { value: 6, label: 'Agd. Análise' },
    ],
    statusConferenceLogisticShow: [
      { value: 2, label: 'Em Execução' },
      { value: 3, label: 'Agd. Criação Pedido' },
    ],
    optionsTypesInventoryLogistic: [
      { value: '', label: 'Selecione' },
      { value: 1, label: 'Normal' },
      { value: 2, label: 'Recontagem' },
    ],
    statusInventoryLogisticOpen: [
      { value: 1, label: 'Aberto' },
    ],
    filterDateInventorysLogistic: '',
    formFilterDateInventorysLogistic: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    startDateInventorysLogistic: null,
    endDateInventorysLogistic: null,
    formFilterInventorysLogistic: {
      status: [1],
      type: [],
      enterprise: []
    },
    filterStatusInventorysLogistic: '1',
    filterTypeInventorysLogistic: '',
    filterEnterpriseLogistic: '',
    showCleanFiltersInventorysLogistic: true,
    serviceOrder: {
      customer: {
        id: null,
        sankhya_id: null,
        name: '',
        email: '',
        document: '',
        phone: '',
        type: 'F',
        zip_code: '',
        address: '',
        number_address: '',
        district: '',
        city: '',
        uf: '',
        state: '',
        complement_address: '',
        ibge: '',
        ie: '',
        is_dealer: false,
        is_prescribers: null,
      },
      prescriber_id: null,
      equipments: [],
      total_amount: 0,
      subtotal: 0,
      shipping_amount: 0,
      shipping_cost: 0,
      discount_amount: 0,
      seller_id: 0,
      created_at: 0,
      obs: null,
      shipping_method: null,
      shipping_estimate_days: null,
      intelipost_response: null,
      indication: null
    },
    approvedServices: [],
    optionsPostalServices: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': '03301', 'label': 'Pac Reverso (03301)' },
      { 'value': '03247', 'label': 'Sedex Reverso (03247)' },
      { 'value': '03182', 'label': 'Sedex 10 Reverso (03182)' },
      { 'value': '03174', 'label': 'Sedex 12 Reverso (03174)' },
      { 'value': '03190', 'label': 'Sedex Hoje Reverso (03190)' },
    ],
    optionsPostalTypes: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'A', 'label': 'Autorização de Postagem' },
      { 'value': 'CA', 'label': 'Coleta domiciliar' },
    ],
    serviceOrderEquipment: {
      id: null,
      service_order_id: null
    },
    equipmentTesting: [
      { 'value': 'flow_calibration', 'label': 'Calibração de fluxo', 'result': '' },
      { 'value': 'humidification', 'label': 'Umidificação', 'result': '' },
      { 'value': 'pressure_calibration', 'label': 'Calibração de pressão', 'result': '' },
      { 'value': 'temperature_sensor', 'label': 'Sensor de temperatura', 'result': '' },
    ],
    statusServiceOrderEquipment: [
      { value: 1, label: "Agd. Recebimento" },
      { value: 2, label: "Agd. Análise" },
      { value: 3, label: "Em Análise" },
      { value: 4, label: "Agd. Orçamento" },
      { value: 5, label: "Agd. Aprovação" },
      { value: 6, label: "Agd. Pagamento" },
      { value: 7, label: "Agd. Execução" },
      { value: 8, label: "Em Execução" },
      { value: 9, label: "Agd. Controle Qualidade" },
      { value: 10, label: "Pendente Ajustes" },
      { value: 11, label: "Agd. Retorno" },
      { value: 12, label: "Agd. Entrega" },
      { value: 13, label: "Com Terceiro" },
      { value: 14, label: "Finalizado" },
      { value: 15, label: "Cancelado" },
      { value: 16, label: "Agd. Peça" },
      { value: 17, label: "Agd. Envio Loja" },
      { value: 18, label: "Agd. Receb. Loja" },
      { value: 19, label: "Agd. Def. Retorno" },
      { value: 20, label: "Agd. Retorno de Equip. Cancelado" },
      { value: 21, label: "Agd. Receber Equip. Cancelado" },
      { value: 22, label: "Agd. Receber Equip. Cancelado na loja" },
    ],
    statusServiceOrderEquipmentOpen: [
      { value: 1, label: "Agd. Recebimento" },
      { value: 2, label: "Agd. Análise" },
      { value: 3, label: "Em Análise" },
      { value: 4, label: "Agd. Orçamento" },
      { value: 5, label: "Agd. Aprovação" },
      { value: 6, label: "Agd. Pagamento" },
      { value: 7, label: "Agd. Execução" },
      { value: 8, label: "Em Execução" },
      { value: 9, label: "Agd. Controle Qualidade" },
      { value: 10, label: "Pendente Ajustes" },
      { value: 11, label: "Agd. Retorno" },
      { value: 12, label: "Agd. Entrega" },
      { value: 13, label: "Com Terceiro" },
      { value: 16, label: "Agd. Peça" },
      { value: 17, label: "Agd. Envio Loja" },
      { value: 18, label: "Agd. Receb. Loja" },
      { value: 19, label: "Agd. Def. Retorno" },
      { value: 20, label: "Agd. Retorno de Equip. Cancelado" },
      { value: 21, label: "Agd. Receber Equip. Cancelado" },
      { value: 22, label: "Agd. Receber Equip. Cancelado na loja" },
    ],
    statusServiceOrderRequest: [
      { value: 1, label: "Agd. Pagamento" },
      { value: 2, label: "Agd. Faturamento" },
      { value: 3, label: "Agd. Liberação" },
      { value: 4, label: "Faturado" },
      { value: 5, label: "Estorno" },
      { value: 6, label: "Cancelado" },
    ],
    statusServiceOrderRequestOpen: [
      { value: 1, label: "Agd. Pagamento" },
      { value: 2, label: "Agd. Faturamento" },
      { value: 3, label: "Agd. Liberação" },
    ],
    stockRoomStatus: [
      { value: 'AC', label: "Agd. Confirmação" },
      { value: 'A', label: "Aberto" },
      { value: 'F', label: "Finalizado" },
      { value: 'FP', label: "Finalizado Parcial" },
      { value: 'EA', label: "Em Atendimento" },
      { value: 'A', label: "Cancelado" },
    ],
    statusBudget:[
      { value: 1, label: "Agd. Confirmação" },
      { value: 2, label: "Pendente aprovação gestor" },
      { value: 3, label: "Pendente aprovação cliente" },
      { value: 4, label: "Aprovado" },
      { value: 5, label: "Rejeitado pelo cliente" },
      { value: 6, label: "Reprovado pelo gestor" },
      { value: 7, label: "Pedido criado" },
      { value: 8, label: "Aguardando liberação" },
    ],
    stockroomStatusOpen:[
      { value: 'A', label: "Aberto" },
      { value: 'EA', label: "Em Atendimento" },
    ],
    filterStatusServiceOrderEquipments: '',
    formFilterServiceOrderEquipments: {
      status: [],
    },
    showCleanFiltersServiceOrderEquipments: false,
    formFilterServiceOrderRequests: {
      status: [],
      payments:[],
      enterprise:[]
    },
    filterStatusServiceOrderRequest: '',
    filterServiceOrderRequestPayment:'',
    filterServiceOrderRequestEnterprise:'',
    startDateServiceOrderRequest: null,
    endDateServiceOrderRequest: null,
    formFilterDateServiceOrderRequest: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    serverOptionsServiceOrderRequests: <ServerOptions>({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      sortType: 'desc',
    }),
    serverItemsLengthServiceOrderRequests: 0,
    itemsServiceOrderRequests: <Item[]>[],
    filterDateServiceOrderRequest: '',
    showCleanFilterServiceOrderRequests: false,
    optionsFilterDateServiceOrderRequests: [
      { 'value': '', 'label': 'Selecione' },
      { 'value': 'now', 'label': 'Hoje' },
      { 'value': 'yesterday', 'label': 'Ontem' },
      { 'value': 'current_month', 'label': 'Mês atual' },
      { 'value': 'last_7_days', 'label': 'Últimos 7 dias' },
      { 'value': 'last_15_days', 'label': 'Últimos 15 dias' },
      { 'value': 'last_30_days', 'label': 'Últimos 30 dias' },
      { 'value': 'last_60_days', 'label': 'Últimos 60 dias' },
      { 'value': 'last_90_days', 'label': 'Últimos 90 dias' },
      { 'value': 'custom', 'label': 'Personalizado' },
    ],
    serverOptionsServiceOrderEquipments: <ServerOptions>({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      sortType: 'desc',
    }),
    serverOptionsServiceOrder: <ServerOptions>({
      page: 1,
      rowsPerPage: 10,
      sortBy: 'id',
      sortType: 'desc',
    }),
    serverItemsLengthServiceOrderEquipments: 0,
    itemsServiceOrderEquipments: <Item[]>[],
    startDateServiceOrderEquipments: null,
    endDateServiceOrderEquipments: null,
    formFilterDateServiceOrderEquipments: {
      filterValue: '',
      startDate: null,
      endDate: null,
    },
    filterDateServiceOrderEquipments: '',
  },
  getters: {
    isMobile: () => {
      return window.innerWidth <= 767;
    },
    areContentsVisible: (state) => state.areContentsVisible,
    getLoggedIn(state) {
      const loggedIn = (getStorage('loggedIn') === 'true')
      if (loggedIn !== undefined) {
        state.loggedIn = loggedIn
      }
      else {
        state.loggedIn = false
      }
      return state.loggedIn
    },
    getAllStatesAddress(state) {
      const statesAddres = [
        { text: 'Acre', value: 'AC' },
        { text: 'Alagoas', value: 'AL' },
        { text: 'Amapá', value: 'AP' },
        { text: 'Amazonas', value: 'AM' },
        { text: 'Bahia', value: 'BA' },
        { text: 'Ceará', value: 'CE' },
        { text: 'Espírito Santo', value: 'ES' },
        { text: 'Goiás', value: 'GO' },
        { text: 'Maranhão', value: 'MA' },
        { text: 'Mato Grosso', value: 'MT' },
        { text: 'Mato Grosso do Sul', value: 'MS' },
        { text: 'Minas Gerais', value: 'MG' },
        { text: 'Pará', value: 'PA' },
        { text: 'Paraíba', value: 'PB' },
        { text: 'Paraná', value: 'PR' },
        { text: 'Pernambuco', value: 'PE' },
        { text: 'Piauí', value: 'PI' },
        { text: 'Rio de Janeiro', value: 'RJ' },
        { text: 'Rio Grande do Norte', value: 'RN' },
        { text: 'Rio Grande do Sul', value: 'RS' },
        { text: 'Rondônia', value: 'RO' },
        { text: 'Roraima', value: 'RR' },
        { text: 'Santa Catarina', value: 'SC' },
        { text: 'São Paulo', value: 'SP' },
        { text: 'Sergipe', value: 'SE' },
        { text: 'Tocantins', value: 'TO' },
        { text: 'Distrito Federal', value: 'DF' },
      ]

      // context.commit('allStatesAddress', statesAddres);
      state.allStatesAddress = statesAddres

      return state.allStatesAddress
    },
    async loadConfig(state) {
      const enterpriseId = getStorage('enterpriseId')
      state.enterprises.forEach((element) => {
        if (element.is_standard == 'S') {
          state.enterprise = element
          state.enterpriseId = element.id
        }
      });
    },
    getEnterprises(state) {
      const enterprises = getStorage('enterprises')
      const enterpriseId = getStorage('enterpriseId')

      if (enterprises != undefined && state.enterprises.length == 0) {
        state.enterprises = JSON.parse(enterprises);
      }
      state.enterprises.forEach((element) => {
        if (element.id == enterpriseId) {
          state.enterprise = element
          state.enterpriseId = element.id
        }
      });
      return state.enterprises
    },
    getEnterprise(state) {
      const enterprise = getStorage('enterprise')
      if (enterprise != undefined && state.enterprise == null) {
        state.enterprise = JSON.parse(enterprise)
      }
      return state.enterprise
    },
    getEnterpriseId(state) {
      const enterpriseId = getStorage('enterpriseId')
      if (enterpriseId != undefined && state.enterpriseId == null) {
        state.enterpriseId = enterpriseId
      }

      return state.enterpriseId
    },
    getEnterpriseById: (state) => (enterpriseId) => {
      const enterprise = state.enterprises.filter(function (enterprise) {
        if (enterprise.id == enterpriseId) {
          return true
        }
        return false
      });

      return enterprise[0]
    },
    getEnterpriseNameById: (state) => (enterpriseId) => {
      const enterprise = state.enterprises.find(enterprise => enterprise.id == enterpriseId);
      return enterprise ? enterprise.name : null;
    },
    getEnterpriseIdForRequest(state) {
      const enterpriseId = getStorage('enterpriseId')
      if (enterpriseId != undefined && state.enterpriseId == null) {
        state.enterpriseId = enterpriseId
      }

      if (['orderCreate', 'orderEdit'].includes(String(router.currentRoute.value.name)) && state?.typeOrder?.use_enterprise_default == 1) {
        return state?.typeOrder.enterprise_id
      }

      if (router.currentRoute.value.name == 'budgetCreate' && state?.typeOrder?.use_enterprise_default == 1) {
        return state?.typeOrder.enterprise_id
      }

      return state.enterpriseId
    },
    getUser(state) {
      if (!state.user?.email) {
        const storageUser = getStorage('user')
        if (storageUser != undefined) {
          state.user = JSON.parse(storageUser);
        }
      }
      return state.user
    },
    getTypeOrders: (state) => (enterpriseId) => {
      // if (state.typeOrders == null) {
      const storageTypeOrders = getStorage('type_orders')
      if (storageTypeOrders != undefined) {
        state.typeOrders = JSON.parse(storageTypeOrders);
      }
      // }

      if (enterpriseId) {
        const enterprise = state.enterprises.filter(enterprise => enterprise.id == enterpriseId)[0];
        let allowedTypeOrders = null
        const allowedTypeOrdersId = enterprise?.type_orders
        if (allowedTypeOrdersId.length) {
          allowedTypeOrders = state.typeOrders.filter(function (typeOrder) {
            return allowedTypeOrdersId.includes(typeOrder.id)
          });
        }
        return allowedTypeOrders;
      }
      return state.typeOrders
    },
    getTypeOrder(state) {
      // if (state.typeOrder == null) {
      const storageTypeOrder = getStorage('type_order')
      if (typeof storageTypeOrder !== 'undefined') {
        state.typeOrder = JSON.parse(storageTypeOrder);
      }
      // }
      state.typeOrderId = state.typeOrder?.id
      return state.typeOrder
    },
    getTypeOrderById: (state) => (typeOrderId) => {
      // if (state.typeOrders == null) {
      const storageTypeOrders = getStorage('type_orders')
      if (storageTypeOrders != undefined) {
        state.typeOrders = JSON.parse(storageTypeOrders);
      }
      // }
      return state.typeOrders.filter(typeOrder => typeOrder.id == typeOrderId)[0];
    },
    getTypeOrderId(state) {
      const typeOrderId = getStorage('typeOrderId')
      if (typeOrderId != undefined && state.typeOrderId == null) {
        state.typeOrderId = typeOrderId
      }

      return state.typeOrderId
    },
    getTypeRentals: (state) => (enterpriseId) => {
      // if (state.typeOrders == null) {
      const storageTypeRentals = getStorage('type_rentals')
      if (storageTypeRentals != undefined) {
        state.typeRentals = JSON.parse(storageTypeRentals);
      }
      // }

      if (enterpriseId) {
        const enterprise = state.enterprises.filter(enterprise => enterprise.id == enterpriseId)[0];
        let allowedTypeRentals = null
        const allowedTypeRentalsId = enterprise?.type_rentals
        if (allowedTypeRentalsId.length) {
          allowedTypeRentals = state.typeRentals.filter(function (typeRental) {
            return allowedTypeRentalsId.includes(typeRental.id)
          });
        }
        return allowedTypeRentals;
      }
      return state.typeRentals
    },
    getTypeRental(state) {
      // if (state.typeOrder == null) {
      const storageTypeRental = getStorage('type_rental')
      if (storageTypeRental !== undefined) {
        state.typeRental = JSON.parse(storageTypeRental);
      }
      // }
      state.typeRentalId = state.typeRental?.id
      return state.typeRental
    },
    getTypeRentalById: (state) => (typeRentalId) => {
      // if (state.typeOrders == null) {
      const storageTypeRentals = getStorage('type_rentals')
      if (storageTypeRentals != undefined) {
        state.typeRentals = JSON.parse(storageTypeRentals);
      }
      // }
      return state.typeRentals.filter(typeRental => typeRental.id == typeRentalId)[0];
    },
    getTypeRentalId(state) {
      const typeRentalId = getStorage('typeRentalId')
      if (typeRentalId != undefined && state.typeRentalId == null) {
        state.typeRentalId = typeRentalId
      }

      return state.typeRentalId
    },
    getPayments: (state) => (typeOrder) => {
      const payments = getStorage('payments')

      if (payments != undefined && state.payments.length == 0) {
        state.payments = JSON.parse(payments);
      }
      const allowedPaymentsId = typeOrder?.payments
      let allowedPayments = null
      if (allowedPaymentsId.length) {
        allowedPayments = state.payments.filter(function (payment) {
          return allowedPaymentsId.includes(payment.id)
        });
      }

      return allowedPayments ?? state.payments
    },
    getPaymentById: (state) => (paymentId) => {
      const payments = getStorage('payments')

      if (payments != undefined && state.payments.length == 0) {
        state.payments = JSON.parse(payments);
      }

      const payment = state.payments.filter(function (payment) {
        if (payment.id == paymentId) {
          return true
        }
        return false
      });

      return payment[0]
    },
    getPaymentsByServiceOrder: (state) => (typeServiceOrder) => {
      const payments = getStorage('payments')

      if (payments != undefined && state.payments.length == 0) {
        state.paymentsServiceOrder = JSON.parse(payments);
      }
      const allowedPaymentsId = typeServiceOrder?.payments
      let allowedPayments = null
      if (allowedPaymentsId.length) {
        allowedPayments = state.paymentsServiceOrder.filter(function (payment) {
          return allowedPaymentsId.includes(payment.id)
        });
      }
      return allowedPayments ?? state.paymentsServiceOrder
    },
    calculateSubtotalOrder(state) {
      let subtotal = 0
      state.order.products.forEach(function (item, i) {
        subtotal += (item.price * item.qty)
      })
      state.order.subtotal = subtotal
      return subtotal
    },
    calculateSubtotalBudget(state) {
      let subtotal = 0
      state.budget.products.forEach(function (item, i) {
        subtotal += (item.price * item.qty)
      })
      state.budget.subtotal = subtotal
      return subtotal
    },
    calculateDiscountBudget(state) {
      let discount = 0
      state.budget.products.forEach(function (item, i) {
        discount += Math.abs(item.total_amount - (item.price * item.qty))
      })
      state.budget.payments.forEach(function (payment, i) {
        if (!payment.is_payment_spot) {
          discount += Math.abs(payment.discount_amount)
        }
      })
      if (discount < 0) {
        discount = discount * -1
      }
      discount += state.spotDiscountAmount
      state.budget.discount_amount = parseFloat(discount.toFixed(2))
      return parseFloat(discount.toFixed(2))
    },
    calculateTotalBudget(state) {
      state.budget.total_amount = (parseFloat(state.budget.subtotal.toFixed(2)) - parseFloat(state.budget.discount_amount.toFixed(2))) + parseFloat(state.budget.shipping_amount.toFixed(2))
      return state.budget.total_amount.toFixed(2)
    },
    calculateDiscountOrder(state) {
      let discount = 0
      state.order.products.forEach(function (item, i) {
        discount += Math.abs(item.total_amount - (item.price * item.qty))
      })
      state.order.payments.forEach(function (payment, i) {
        if (!payment.is_payment_spot) {
          discount += Math.abs(payment.discount_amount)
        }
      })
      if (discount < 0) {
        discount = discount * -1
      }
      discount += state.spotDiscountAmount
      state.order.discount_amount = parseFloat(discount.toFixed(2))
      return parseFloat(discount.toFixed(2))
    },
    calculateTotalOrder(state) {
      state.order.total_amount = (parseFloat(state.order.subtotal.toFixed(2)) - parseFloat(state.order.discount_amount.toFixed(2))) + parseFloat(state.order.shipping_amount.toFixed(2))
      return state.order.total_amount.toFixed(2)
    },
    calculateHavePay(state) {
      let totalPay = 0;
      let discount = 0
      state.order.payments.forEach(function (payment, i) {
        totalPay += (payment.subtotal)
        // if (!payment.is_payment_spot) {
        //   discount += payment.discount_amount
        // }
      })
      let totalProducts = 0
      state.order.products.forEach(function (item, i) {
        totalProducts += item.total_amount
      })

      if (discount < 0) {
        discount = discount * -1
      }
      discount += state.spotDiscountAmount
      const havePay = (((totalProducts - discount) + state.order.shipping_amount) - totalPay)
      return havePay < 0 ? 0 : parseFloat(havePay.toFixed(2))
    },
    calculateHavePayBudget(state){
      let totalPay = 0;
      let discount = 0
      state.budget.payments.forEach(function (payment, i) {
        totalPay += (payment.subtotal)
        // if (!payment.is_payment_spot) {
        //   discount += payment.discount_amount
        // }
      })
      let totalProducts = 0
      state.budget.products.forEach(function (item, i) {
        totalProducts += item.total_amount
      })

      if (discount < 0) {
        discount = discount * -1
      }
      discount += state.spotDiscountAmount
      const havePay = (((totalProducts - discount) + state.budget.shipping_amount) - totalPay)
      return havePay < 0 ? 0 : parseFloat(havePay.toFixed(2))
    },
    calculateTotalPaid(state) {
      let totalPaid = 0
      state.order.payments.forEach(function (payment, i) {
        totalPaid += payment.total_amount
      })

      return parseFloat(totalPaid.toFixed(2))
    },
    calculateTotalPaidBudget(state) {
      let totalPaid = 0
      state.budget.payments.forEach(function (payment, i) {
        totalPaid += payment.total_amount
      })

      return parseFloat(totalPaid.toFixed(2))
    },
    calculateSpotDiscountAmount(state) {
      state.spotDiscountAmount = 0;
      state.order.payments.forEach(function (payment, i) {
        if (payment.is_payment_spot) {
          state.spotDiscountAmount += parseFloat((payment.subtotal * (payment.discount_percentage / 100)).toFixed(2))
        }
      })
      return state.spotDiscountAmount < 0 ? 0 : state.spotDiscountAmount
    },
    calculateSpotDiscountAmountBudget(state) {
      state.spotDiscountAmount = 0;
      state.budget.payments.forEach(function (payment, i) {
        if (payment.is_payment_spot) {
          state.spotDiscountAmount += parseFloat((payment.subtotal * (payment.discount_percentage / 100)).toFixed(2))
        }
      })
      return state.spotDiscountAmount < 0 ? 0 : state.spotDiscountAmount
    },
    calculateNotSpotDiscountAmount(state) {
      let notSpotDiscountAmount = 0;
      state.order.payments.forEach(function (payment, i) {
        if (!payment.is_payment_spot) {
          notSpotDiscountAmount += (payment.subtotal * (payment.discount_percentage / 100))
        }
      })
      return notSpotDiscountAmount < 0 ? 0 : parseFloat(notSpotDiscountAmount.toFixed(2))
    },
    calculateSubtotalRental(state) {
      let subtotal = 0
      state.rental.products.forEach(function (item, i) {
        subtotal += (item.price * item.qty)
      })
      state.rental.subtotal = subtotal
      return subtotal
    },
    calculateDiscountRental(state) {
      let discount = 0
      state.rental.products.forEach(function (item, i) {
        discount += Math.abs(item.total_amount - (item.price * item.qty))
      })
      // state.rental.payments.forEach(function(payment, i) {
      //   if (!payment.is_payment_spot) {
      //     discount += Math.abs(payment.discount_amount)
      //   }
      // })
      if (discount < 0) {
        discount = discount * -1
      }
      state.rental.discount_amount = parseFloat(discount.toFixed(2))
      return parseFloat(discount.toFixed(2))
    },
    calculateTotalRental(state) {
      state.rental.total_amount = (state.rental.subtotal - (state.rental.discount_amount)) + state.rental.shipping_amount
      return state.rental.total_amount.toFixed(2)
    },
    getImageProduct: (state) => (product) => {
      return product.image == 'https://www.cpaps.com.br/media/catalog/productno_selection' ? '~public/img/no_img.jpg' : product.image;
    },
    productHasControl: (state) => (product) => {
      // return state.todos.find(todo => todo.id === id)
      return product.type_control == 'E' || product.type_control == 'L' ? true : false
    },
    currencyFormat: (state) => (value, decimals = 2, symbol = 'R$') => {
      return symbol + ' ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(value);
    },
    formatDate: (state) => (value, addHour = false) => {
      let dateFormated = ''
      if (value) {
        if (!value.includes('T')) {
          // Gambi para add meia noite no UTC
          value += "T03:00:00.000000Z"
        }
        const date = new Date(value)
        if (addHour) {
          dateFormated = date.toLocaleDateString()
          dateFormated = dateFormated + " " + date.toLocaleTimeString()
        } else {
          dateFormated = date.toLocaleDateString()
        }
      }
      return dateFormated
    },
    formatDateLogistic: (state) => (dateStr) => {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      return date.toISOString().split('T')[0]
    },
    formatDocument: (state) => (value) => {
      const cnpjCpf = ('' + value).replace(/\D/g, '');
      if (cnpjCpf.length > 0) {
        if (cnpjCpf.length === 11) {
          //eslint-disable-next-line
          return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
        }
        //eslint-disable-next-line
        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
      }
      return ''
    },
    formatPhone: (state) => (number) => {
      // número = +5599999999999
      const cleaned = ('' + number).replace(/\D/g, '');
      // número = 5599999999999
      const match = cleaned.match(/^(\d{2})(\d{4}|\d{5})(\d{4})$/);
      // número = 55 99 99999 9999
      if (match) {
        return ['(', match[1], ') ', match[2], '-', match[3]].join('')
      }
      // número = (99) 99999-9999
      return '';
    },
    formatNumber: (state) => (value) => {
      return ('' + value).replace('.', ',');
    },
    showImageProduct: (state) => (product) => {
      if (!product.image || product.image == 'https://www.cpaps.com.br/media/catalog/productno_selection') {
        return false
      }
      return true
    },
    getImageBlob: (state) => (product) => {
      let imageUrl = "../../../public/img/no_img.jpg";
      let imageEncode = product?.image_encode ?? null
      if (!imageEncode && product?.image) {
        imageEncode = product?.image ?? null
      }
      if (imageEncode) {
        imageUrl = 'data:image/bmp;base64,' + imageEncode;
      }
      return imageUrl
    },
    getIconPayment: (state) => (payment) => {
      if (payment.payment_code == 'cielo_lio' || payment.payment_code == 'maxipago_link') {
        return 'icon_credit_card'
      } else if (state.paymentsCodesCash.includes(payment.payment_code)) {
        return 'icon_cash'
      } else if (state.paymentsCodesBillet.includes(payment.payment_code)) {
        return 'icon_billet'
      } else if (state.paymentsCodesPix.includes(payment.payment_code)) {
        return 'icon_pix'
      } else if (payment.payment_code == 'billet_invoiced') {
        return 'icon_billet'
      } else if (state.paymentsCodesWireTransfer.includes(payment.payment_code)) {
        return 'icon_wire_transfer'
      } else if (payment.payment_code == 'voucher') {
        return 'icon_voucher'
      }
    },
    getCssClassStatus: (state) => (status) => {
      if (status == 1) {
        return 'awaiting-payment'
      } else if (status == 2) {
        return 'awaiting-invoice'
      } else if (status == 3) {
        return 'awaiting-release'
      } else if (status == 4) {
        return 'awaiting-payment-confimation'
      } else if (status == 5) {
        return 'delivered'
      } else if (status == 6) {
        return 'canceled'
      } else if (status == 7) {
        return 'awaiting-post'
      } else if (status == 8) {
        return 'awaiting-delivery'
      } else if (status == 9) {
        return 'voided'
      } else if (status == 10) {
        return 'awaiting-confirmation'
      } else if (status == 11) {
        return 'canceled'
      } else if (status == 12) {
        return 'awaiting-credit'
      }
    },
    getCssClassStatusWarehouse: (state) => (status) => {
      if (status == 'AC') {
        return 'awaiting-confirmation'
      } else if (status == 'A') {
        return 'awaiting-invoice'
      } else if (status == 'F') {
        return 'delivered'
      } else if (status == 'FP') {
        return 'awaiting-release'
      } else if (status == 'EA') {
        return 'awaiting-post'
      } else if (status == 'C') {
        return 'canceled'
      } else if (status == 'AL'){
        return 'awaiting-release'
      } else if (status == 'SR'){
        return 'canceled'
      }
    },
    getCssClassServiceOrder: (state) => (status) => {
      if (status == null) {
        return 'canceled'
      }
      if (status == '1') {
        return 'open'
      }
      if (status == '2') {
        return 'canceled'
      }
      if (status == '3') {
        return 'finished'
      }
      if (status == '4') {
        return 'awaiting-receiving'
      }
      if (status == '5') {
        return 'in_progress'
      }
      if (status == '6') {
        return 'awaiting-invoice-pj'
      }
      if (status == '7') {
        return 'awaiting-invoice-pj'
      }
      if (status == '8') {
        return 'awaiting-receiving'
      }
    },
    getCssClassServiceOrderEquipment: (state) => (status) => {
      if (status == null) {
        return 'canceled'
      }
      if (status == '1') {
        return 'waiting'
      }
      if (status == '2') {
        return 'waiting'
      }
      if (status == '3') {
        return 'under_analysis'
      }
      if (status == '4') {
        return 'waiting'
      }
      if (status == '5') {
        return 'waiting'
      }
      if (status == '6') {
        return 'waiting'
      }
      if (status == '7') {
        return 'waiting'
      }
      if (status == '8') {
        return 'running'
      }
      if (status == '9') {
        return 'waiting'
      }
      if (status == '10') {
        return 'waiting'
      }
      if (status == '11') {
        return 'waiting'
      }
      if (status == '12') {
        return 'waiting'
      }
      if (status == '13') {
        return 'waiting'
      }
      if (status == '14') {
        return 'finished'
      }
      if (status == '15') {
        return 'canceled'
      }
      if (status == '17') {
        return 'waiting'
      }
      if (status == '18') {
        return 'waiting'
      }
      if (status == '19') {
        return 'waiting'
      }
      if (status == '20') {
        return 'waiting'
      }
      if (status == '21') {
        return 'waiting'
      }
      if (status == '22') {
        return 'waiting'
      }
      if (status == '23'){
        return 'waiting'
      }
    },
    getCssClassServiceOrderBudget: (state) => (status) => {
      if (status == null) {
        return 'canceled'
      }
      if (status == '1') {
        return 'waiting_send'
      }
      if (status == '2') {
        return 'canceled'
      }
      if (status == '3') {
        return 'waiting_release'
      }
      if (status == '4') {
        return 'waiting_approval'
      }
      if (status == '5') {
        return 'approved'
      }
      if (status == '6') {
        return 'awaiting-invoice-pj'
      }
    },
    getCssClassServiceOrderRequestedOrder: (state) => (status) => {
      if (status == null) {
        return 'canceled'
      }
      if (status == 1) {
        return 'awaiting_payment'
      }
      if (status == '2') {
        return 'awaiting_invoice'
      }
      if (status == '3') {
        return 'awaiting_release'
      }
      if (status == '4') {
        return 'delivered'
      }
      if (status == '5') {
        return 'canceled'
      }
      if (status == '6') {
        return 'voided'
      }
    },
    getCssClassStatusPayment: (state) => (status) => {
      if (status == 'A') {
        return 'awaiting-payment'
      } else if (status == 'P') {
        return 'paid'
      } else if (status == 'C') {
        return 'canceled'
      } else if (status == 'R') {
        return 'requested-refund'
      } else if (status == 'F') {
        return 'refound-finalized'
      } else if (status == 'D') {
        return 'refound-denied'
      }
    },
    getStatusPayment: (state) => (payment) => {
      if (payment.status == 'A') {
        return 'Aguardando Pagamento'
      } else if (payment.status == 'P') {
        return 'Pago'
      } else if (payment.status == 'C') {
        return 'Cancelado'
      } else if (payment.status == 'R') {
        return 'Reembolso Solicitado'
      } else if (payment.status == 'F') {
        return 'Reembolso Finalizado'
      } else if (payment.status == 'D') {
        return 'Reembolso negado'
      }
    },
    getCssClassStatusRental: (state) => (status) => {
      if (status == 1) {
        return 'awaiting-contract'
      } else if (status == 2) {
        return 'awaiting-signature'
      } else if (status == 3) {
        return 'awaiting-payment'
      } else if (status == 4) {
        return 'awaiting-invoice'
      } else if (status == 5) {
        return 'awaiting-return'
      } else if (status == 6) {
        return 'active'
      } else if (status == 7) {
        return 'finished'
      } else if (status == 8) {
        return 'canceled'
      } else if (status == 9) {
        return 'awaiting-release'
      } else if (status == 10) {
        return 'awaiting-confirmation-pj'
      } else if (status == 11) {
        return 'awaiting-confirmation-change-plan'
      } else if (status == 12) {
        return 'overdue'
      } else if (status == 13){
        return 'awaiting-contract'
      }
    },
    getCssClassStatusBudget: (state) => (status) => {
      if (status == null) {
        return 'canceled'
      }
      if (status == '1') {
        return 'awaiting-invoice-pj'
      }
      if (status == '2') {
        return 'open'
      }
      if (status == '3') {
        return 'open'
      }
      if (status == '4') {
        return 'finished'
      }
      if (status == '5') {
        return 'canceled'
      }
      if (status == '6') {
        return 'canceled'
      }
      if (status == '7') {
        return 'finished'
      }
      if (status == '8') {
        return 'awaiting-receiving'
      }
    },
    getStatusPaymentRental: (state) => (payment) => {
      if (payment.status == 'A') {
        return 'Aguardando Pagamento'
      } else if (payment.status == 'P') {
        return 'Pago'
      } else if (payment.status == 'C') {
        return 'Cancelado'
      } else if (payment.status == 'F') {
        return 'Faturado'
      } else if (payment.status == 'L') {
        return 'Agd Liberação'
      } else if (payment.status == 'W') {
        return 'Agd. Confirmação de Pagamento'
      } else if (payment.status == 'I'){
        return 'Inadimplente'
      }
    },
    getOrder(state) {
      return state.order
    },
    checkPermissionsUser: (state) => (permission) => {
      if (!state.user?.email) {
        const storageUser = getStorage('user')
        if (storageUser != undefined) {
          state.user = JSON.parse(storageUser);
        }
      }
      // return state.state
      return state.user?.permissions?.includes(permission)
    },
    getQrCodePix: (state) => (payment) => {
      if (!payment?.response) {
        return ""
      }
      const response = JSON.parse(payment.response)
      return response?.textoImagemQRcode
    },
    getUrlLinkPayment: (state) => (payment) => {
      if (!payment?.response) {
        return ""
      }
      const response = JSON.parse(payment.response)
      return response?.result.url
    },
    getPaymentCodeByPaymentId: (state) => (paymentId) => {
      if (state.payments.length == 0) {
        state.payments = JSON.parse(getStorage('payments'));
      }
      const payment = state.payments.filter(function (item) {
        if (item.id == paymentId) {
          return true
        }
      });
      return payment[0]?.payment_code
    },
    getAllStatusStockroom(state) {
      return state.stockRoomStatus
    },
    getAllStatusBudget(state){
      return state.statusBudget
    },
    getAllStatusOrder(state) {
      return state.statusOrder
    },
    getAllServiceOrder(state) {
      return state.statusServiceOrder
    },
    getAllStatusEquipment(state) {
      return state.statusEquipment
    },
    getAllStatusRental(state) {
      return state.statusRental
    },
    getAllStatusScrums(state) {
      return state.statusScrums
    },
    getExpiresIn(state) {
      const dateString = String(getStorage('expiresIn')).replaceAll('"', "")
      const dateExpire = new Date(dateString);
      state.expiresIn = dateExpire
      return state.expiresIn
    },
    getToken(state) {
      if (!state.token) {
        let token = getStorage('token')
        if (token !== undefined) {
          token = token.substring(1, token.length - 1);
          state.token = token
        }
      }
      return state.token
    },
    validateDocument: (state) => (val) => {
      if (val.length == 14) {
        let cpf = val.trim();

        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace('-', '');
        cpf = cpf.split('');

        let v1 = 0;
        let v2 = 0;
        let aux = false;

        for (let i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
            aux = true;
          }
        }

        if (aux == false) {
          return false;
        }

        for (let i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 == 10) {
          v1 = 0;
        }

        if (v1 != cpf[9]) {
          return false;
        }

        for (let i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 == 10) {
          v2 = 0;
        }

        if (v2 != cpf[10]) {
          return false;
        } else {
          return true;
        }
      } else if (val.length == 18) {
        let cnpj = val.trim();

        cnpj = cnpj.replace(/\./g, '');
        cnpj = cnpj.replace('-', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.split('');

        let v1 = 0;
        let v2 = 0;
        let aux = false;

        for (let i = 1; cnpj.length > i; i++) {
          if (cnpj[i - 1] != cnpj[i]) {
            aux = true;
          }
        }

        if (aux == false) {
          return false;
        }

        for (let i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
          if (p1 >= 2) {
            v1 += cnpj[i] * p1;
          } else {
            v1 += cnpj[i] * p2;
          }
        }

        v1 = (v1 % 11);

        if (v1 < 2) {
          v1 = 0;
        } else {
          v1 = (11 - v1);
        }

        if (v1 != cnpj[12]) {
          return false;
        }

        for (let i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
          if (p1 >= 2) {
            v2 += cnpj[i] * p1;
          } else {
            v2 += cnpj[i] * p2;
          }
        }

        v2 = (v2 % 11);

        if (v2 < 2) {
          v2 = 0;
        } else {
          v2 = (11 - v2);
        }

        if (v2 != cnpj[13]) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    maskSensitive: (state) => (value, type) => {
      let masked = ''
      switch (type) {
        case 'name':
          masked = `${value}`.slice(0, `${value}`.indexOf(' ')).padEnd(`${value}`.length, '*');
          break;
        case 'email':
          // return value
          masked = `${value}`.slice(0, `${value}`.indexOf('@') - (`${value}`.indexOf('@') / 2)).padEnd(`${value}`.indexOf('@'), '*') + `${value}`.slice(`${value}`.indexOf('@') - 1);
          break;
        case 'document':
          //eslint-disable-next-line
          let cnpjCpf = `${value}`.replace(/\D/g, '');
          if (cnpjCpf.length > 0) {
            if (cnpjCpf.length === 11) {
              //eslint-disable-next-line
              cnpjCpf = cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
              masked = cnpjCpf.slice(0, 3).padEnd(`${cnpjCpf}`.length, '*') + cnpjCpf.slice(`${cnpjCpf}`.indexOf('-') + 1)
              break;
            }
            //eslint-disable-next-line
            cnpjCpf = cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
            masked = cnpjCpf.slice(0, 4).padEnd(`${cnpjCpf}`.length, '*') + cnpjCpf.slice(`${cnpjCpf}`.indexOf('-') + 1)
          }
          break;

        default:
          break;
      }

      return masked;
    },
    getInfoPayCielo: (state) => (payment) => {
      const data = [];
      if (!payment?.notification_response) {
        return data
      }
      const response = JSON.parse(payment.notification_response)

      response.transactions.forEach(function (transaction, i) {
        const info = {
          nsu: transaction.number,
          numcv: transaction.external_id,
          authorization_code: transaction.authorization_code,
          numpv: response.merchant,
          mask: transaction.card.mask,
          brand: transaction.card.brand,
          amount: (transaction.amount / 100),
          installments: transaction.payment_fields.number_of_quotas == 0 ? 1 : transaction.payment_fields.number_of_quotas,
          type: transaction.payment_fields.primary_product_name
        }
        data.push(info)
      })

      return data
    },
    getInfoPayMaxipago: (state) => (payment) => {
      const data = [];
      if (!payment?.notification_response) {
        return data
      }
      const response = JSON.parse(payment.notification_response)
      const transaction = response.transaction;
      const info = {
        nsu: transaction.approvalCode,
        numcv: transaction.transactionId,
        authorization_code: transaction.processorReferenceNumber,
        numpv: transaction.processorTransactionID,
        mask: transaction.paymentType,
        brand: transaction.creditCardType,
        amount: transaction.transactionAmount.replace(',', ''),
        installments: response?.result?.transactionDetail?.payType?.creditCard?.numberOfInstallments ?? 1,
        type: "CREDITO"
      }
      data.push(info)

      return data
    },
    getInfoPaymentRental: (state) => (payment) => {
      const data = [];
      if (!payment?.notification_response) {
        return data
      }
      const response = JSON.parse(payment.notification_response)
      const transaction = response.last_transaction;
      const info = {
        nsu: transaction.acquirer_nsu,
        numcv: transaction.acquirer_tid,
        authorization_code: transaction.acquirer_auth_code,
        numpv: transaction.id,
        mask: transaction.card.last_four_digits,
        brand: transaction.card.brand,
        amount: transaction.amount / 100,
        installments: 1,
        type: transaction.funding_source == 'credit' ? "CREDITO" : "DEBITO"
      }
      data.push(info)

      return data
    },
    getGoalYears(state) {
      const yearBase = 2022
      const yearCurrent = new Date().getFullYear();
      const years = []
      for (let index = yearBase; index <= yearCurrent; index++) {
        // const element = array[index];
        years.push(index)
      }

      return years
    },
    getMonthName: (state) => (value) => {
      const month = state.monthsYear.filter(function (elem) {
        if (elem.value == value) {
          return true
        }
        return false
      });

      return month[0].label
    },
    getWorkingDaysByMonth: (state) => (month, year) => {
      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 0);

      let workingDays = 0;
      while (firstDayOfMonth <= lastDayOfMonth) {
        const dayOfWeek = firstDayOfMonth.getDay();
        if (dayOfWeek !== 0) {
          workingDays++;
        }
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
      }

      return workingDays;
    },
    getDeadlines(state) {
      const deadlines = getStorage('deadlines')
      if (deadlines != undefined) {
        state.deadlines = JSON.parse(deadlines);
      }
      return state.deadlines
    },
    getDeadlineById: (state) => (deadlineId) => {
      // if (state.typeOrders == null) {
      const storageDeadlines = getStorage('deadlines')
      if (storageDeadlines != undefined) {
        state.deadlines = JSON.parse(storageDeadlines);
      }
    // }
    return state.deadlines.filter(deadline => deadline.id == deadlineId)[0];
  },
  getIndicationById: (state) => (indicationId) => {
    if (!indicationId) {
      return "-"
    }
    const indication = state.indications.filter(typeOrder => typeOrder.value == indicationId)[0];
    return indication.label
  },
  getTypeInventoryById: (state) => (typeId) => {
    const typeInventory = state.optionsTypesInventory.filter(type => type.value == typeId)[0];
    return typeInventory.label
  },
  getTypeInventoryTechAssistById: (state) => (typeId) => {
    const typeInventory = state.TypeInventoryTechAssist.filter(type => type.value == typeId)[0];
    return typeInventory.label
  },
  getStatusInventoryById: (state) => (statusId) => {
    const status = state.statusInventory.filter(status => status.value == statusId)[0];
    return status.label
  },
  getStatusInventoryTechAssistById: (state) => (statusId) => {
    const status = state.statusInventoryTechAssist.filter(status => status.value == statusId)[0];
    return status.label
  },
  getStatusSingleCountById: (state) => (statusId) => {
    const status = state.statusSingleCount.filter(status => status.value == statusId)[0];
    return status.label
  },
  getLocaleInventoryById: (state) => (localeId) => {
    const locale = state.optionsLocaleInventory.filter(locale => locale.value == localeId)[0];
    return locale.label
  },
  getStatusInventoryItemById: (state) => (localeId) => {
    const locale = state.statusInventoryItem.filter(locale => locale.value == localeId)[0];
    return locale.label
  },
  getStatusInventoryResumeItemById: (state) => (localeId) => {
    const locale = state.statusInventoryResumeItem.filter(locale => locale.value == localeId)[0];
    return locale.label
  },
  getCssClassStatusItemInventory: (state) => (status) => {
    if (status == 1) {
      return 'ok'
    }
    return 'divergent'
  },
  getStatusConferenceById: (state) => (statusId) => {
    const status = state.statusConference.filter(status => status.value == statusId)[0];
    return status.label
  },
  getStatusConferenceLogisticById: (state) => (statusId) => {
    const status = state.statusConferenceLogistic.filter(status => status.value == statusId)[0];
    return status.label
  },
  getStatusConferenceEntraceById: (state) => (statusId) =>{
    const status = state.statusConferenceEntrance.filter(status => status.value == statusId)[0];
    return status.label
  },
  getTypeConferenceById: (state) => (typeId) => {
    const typeInventory = state.optionsTypesConference.filter(type => type.value == typeId)[0];
    return typeInventory.label
  },
  getCssClassStatusItemConference: (state) => (status) => {

      if (status == 1) {
        return 'ok'
      } else if (status == 2) {
        return 'divergent'
      }
    },
    getCssClassStatusItemConferenceProduct: (state) => (status) => {
      if (status == 2) {
        return 'execution-status'
      } else if (status == 3) {
        return 'awaiting-analyzes'
      }
    },
    getStatusConferenceItemById: (state) => (localeId) => {
      const locale = state.statusConferenceItem.filter(locale => locale.value == localeId)[0];
      return locale.label
    },
    getLabelPlaceForConference: (state) => (place) => {
      if (place == 1) {
        return 'Venda'
      } else if (place == 2) {
        return 'Seminovos'
      } else if (place == 3) {
        return 'Adaptação'
      } else if (place == 4) {
        return 'Mostruário'
      } else if (place == 5) {
        return 'Locação'
      }
    },
    getEnterpriseInventoryLogistic: (state) => (enterprise) => {
      if (enterprise == 1) {
        return 'Matriz'
      } else if (enterprise == 2) {
        return 'Investiana'
      } else if (enterprise == 3) {
        return 'Atacadista'
      }
      return '-'
    },
    getStatusInventoryLogistic: (state) => (statusId) => {
      const status = state.statusInventoryLogistic.filter(status => status.value == statusId)[0]
      if (status) {
        return status.label
      }
      return '-';
    },
    getStatusInventoryLogisticProduct: (state) => (statusId) => {
      const status = state.statusInventoryProduct.filter(status => status.value == statusId)[0]
      if (status) {
        return status.label
      }
      return '-';
    },
    getStatusConferenceLogisticProduct: (state) => (statusId) => {
      const status = state.statusConferenceLogisticShow.filter(status => status.value == statusId)[0]
      if (status) {
        return status.label
      }
      return '-';
    },
    getTypeInventoryLogistic: (state) => (type) => {
      let label = 'Normal'
      if (type == 2) {
        label = 'Recontagem'
      }
      return label
    },
    getTypeServiceOrderId(state) {
      const typeServiceOrderId = getStorage('typeServiceOrderId')
      // if (typeServiceOrderId != undefined && state.typeOrderId == null) {
      //   state.typeOrderId = typeOrderId
      // }

      // return state.typeOrderId
      return typeServiceOrderId
    },
    getTypeServiceOrders: (state) => (enterpriseId) => {
      const storageTypeServiceOrders = getStorage('type_service_orders')
      if (storageTypeServiceOrders != undefined) {
        state.typeServiceOrders = JSON.parse(storageTypeServiceOrders);
      }

      if (enterpriseId) {
        const enterprise = state.enterprises.filter(enterprise => enterprise.id == enterpriseId)[0];
        let allowedTypeServiceOrder = null
        const allowedTypeServiceOrderId = enterprise?.type_service_orders
        if (allowedTypeServiceOrderId.length) {
          allowedTypeServiceOrder = state.typeServiceOrders.filter(function (typeServiceOrder) {
            return allowedTypeServiceOrderId.includes(typeServiceOrder.id)
          });
        }
        return allowedTypeServiceOrder;
      }
      return state.typeServiceOrders
    },
    getTypeServiceOrderById: (state) => (id) => {
      // if (state.typeOrders == null) {
      const storageTypeOrders = getStorage('type_service_orders')
      if (storageTypeOrders != undefined) {
        state.typeServiceOrders = JSON.parse(storageTypeOrders);
      }
      // }
      return state.typeServiceOrders.filter(typeOrder => typeOrder.id == id)[0];
    },
    getApprovedServices: (state) => {
      const storageApprovedServices = getStorage('approved_services')
      if (storageApprovedServices != undefined) {
        state.approvedServices = JSON.parse(storageApprovedServices);
      }
      return state.approvedServices
    },
    getApprovedServicesByIds: (state) => (ids) => {
      return state.approvedServices.filter(item => ids.indexOf(item.id) != -1)
    },
    getCustomerTypeLabelByType: (state) => (type) => {
      return type == 'F' ? 'Física' : 'Jurídica'
    },
    convertSecondToMinutes: (state) => (value) => {
      if (isNaN(value) || value === '-' || value === 0 || value === null) {
        return '-';
      }
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value % 3600) / 60); // Encontra o número inteiro de minutos
      const secondsRemaining = value % 60; // Encontra os segundos restantes

      let result = "";

      if (hours > 0) {
        result = hours + "H";
      }
      if (minutes > 0) {
        if (result) {
          result += ":"
        }
        result += minutes + "M"
      }
      if (secondsRemaining > 0) {
        if (result) {
          result += ":"
        }
        result += secondsRemaining + "S"
      }
      // Retorna o resultado como uma string formatada
      return result;
    },
    getChecklistLabel: (state) => (value) => {
      switch (value) {
        case 'V':
          return 'CONFORME'
        // break;
        case 'X':
          return 'NÃO CONFORME'
        // break;
        case 'N/A':
          return 'NÃO SE APLICA'
        default:
          return '-'
      }
    },
    getEquipmentTestInformation: (state) => (test) => {
      const labelKey = Object.keys(test)[0];
      const resultValue = Object.values(test)[0];
      const value = resultValue === "1" ? "Aprovado" : "Reprovado";

      switch (labelKey) {
        case 'flow_calibration':
          return 'Calibração de fluxo: ' + value
        case 'humidification':
          return 'Umidificação: ' + value
        case 'pressure_calibration':
          return 'Calibração de pressão: ' + value
        case 'temperature_sensor':
          return 'Sensor de temperatura: ' + value
        default:
          return '-'
      }
    },
    getAllStatusServiceOrderEquipment(state) {
      return state.statusServiceOrderEquipment
    },
    getAllStatusServiceOrderRequest(state) {
      return state.statusServiceOrderRequest
    },
  },
  mutations: {
    setCustomConfirm(state, customConfirm) {
      state.customConfirm = customConfirm;
    },
    setMessage(state, payload) {
      state.message = payload.message;
      state.messageType = payload.type;
      state.redirectRoute = payload.route || null;
    },
    toggleContentsVisibility(state) {
        state.areContentsVisible = !state.areContentsVisible;
    },
    setReloadOnModalClose(state, reload) {
      state.reloadOnModalClose = reload;
    },
    resetMessage(state) {
      state.message = '';
      state.messageType = '';
      state.redirectRoute = null;
      state.reloadOnModalClose = false;
    },
    addToModalHistory(state, modalId) {
      state.modalHistory.push(modalId);
    },
    removeFromModalHistory(state) {
      state.modalHistory.pop();
    },
    setUser(state, user) {
      state.user = user
      saveStorage('user', user)
    },
    setToken(state, token) {
      state.token = token;
      clearStorage('token')
      saveStorage('token', token)
    },
    setExpiresIn(state, expiresIn) {
      const now = new Date()
      now.setSeconds(now.getSeconds() + expiresIn);
      state.expiresIn = now
      clearStorage('expiresIn')
      saveStorage('expiresIn', now.toISOString())
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
      clearStorage('loggedIn')
      saveStorage('loggedIn', loggedIn)
    },
    setTypeOrders(state, typeOrders) {
      state.typeOrders = typeOrders
      saveStorage('type_orders', typeOrders)
    },
    setTypeServiceOrders(state, typeServiceOrders) {
      state.typeServiceOrders = typeServiceOrders
      saveStorage('type_service_orders', typeServiceOrders)
    },
    setApprovedServices(state, approvedServices) {
      state.approvedServices = approvedServices
      saveStorage('approved_services', approvedServices)
    },
    setTypeOrder(state, typeOrder) {
      state.typeOrder = typeOrder
      saveStorage('type_order', typeOrder)
    },
    setTypeOrderId(state, typeOrderId) {
      state.typeOrderId = typeOrderId
      saveStorage('typeOrderId', typeOrderId)
    },
    setTypeRentals(state, typeRentals) {
      state.typeRentals = typeRentals
      saveStorage('type_rentals', typeRentals)
    },
    setTypeRental(state, typeRental) {
      state.typeRental = typeRental
      saveStorage('type_rental', typeRental)
    },
    setTypeRentalId(state, typeRentalId) {
      state.typeRentalId = typeRentalId
      saveStorage('typeRentalId', typeRentalId)
    },
    setDeadlines(state, deadlines) {
      state.deadlines = deadlines
      saveStorage('deadlines', deadlines)
    },
    setEnterprises(state, enterprises) {
      state.enterprises = enterprises
      saveStorage('enterprises', enterprises)
      if (state.enterpriseId) {
        const currentEnterprise = state.enterprises.filter(enterprise => enterprise.id == state.enterpriseId)[0];
        if (currentEnterprise) {
          state.enterprise = currentEnterprise;
          saveStorage('enterprise', currentEnterprise)
          saveStorage('enterpriseId', currentEnterprise.id)
        }
      }
    },
    setEnterprise(state, enterprise) {
      state.enterprise = enterprise
      saveStorage('enterprise', enterprise)
      if (state.enterpriseId != enterprise.id) {
        state.enterpriseId = parseInt(enterprise.id)
        saveStorage('enterpriseId', enterprise.id)
      }
    },
    setEnterpriseId(state, enterpriseId) {
      state.enterpriseId = parseInt(enterpriseId)
      saveStorage('enterpriseId', enterpriseId)
      if (state.enterpriseId != state.enterprise.id) {
        const newEnterprise = state.enterprises.filter(enterprise => enterprise.id == enterpriseId)[0];
        state.enterprise = newEnterprise
        saveStorage('enterprise', newEnterprise)
      }
    },
    setPayments(state, payments) {
      state.payments = payments
      saveStorage('payments', payments)
    },
    setServiceOrderEquipmentIdToOrder(state, serviceOrderId) {
      state.order.service_order_equipment_id = serviceOrderId
    },
    setServiceOrderBudgetIdToOrder(state, serviceOrderBudgetId) {
      state.order.service_order_budget_id = serviceOrderBudgetId
    },
    setProductToOrder(state, product) {
      const hasItem = state.order.products.find(item => item.id == product.id && product.type_control == 'N');
      const products = state.order.products;
      if ('key' in product) {
        products[product.key] = product
      } else if (!hasItem) {
        products.push(product)
      }
      state.order.products = products
    },
    setProductToBudget(state, product) {
      const hasItem = state.budget.products.find(item => item.id == product.id && product.type_control == 'N');
      const products = state.budget.products;
      if ('key' in product) {
        products[product.key] = product
      } else if (!hasItem) {
        products.push(product)
      }
      state.budget.products = products
    },
    removeProductToOrder(state, product) {
      const products = state.order.products
      if ('key' in product) {
        products.splice(product.key, 1);
      }
      state.order.products = products
    },
    setProductToRental(state, product) {
      const hasItem = state.rental.products.find(item => item.id == product.id && product.type_control == 'N');
      const products = state.rental.products;
      if ('key' in product) {
        products[product.key] = product
      } else if (!hasItem) {
        products.push(product)
      }
      state.rental.products = products
    },
    removeProductToRental(state, product) {
      let products = state.rental.products
      if ('key' in product) {
        products.splice(product.key, 1);
        if (product?.parent_id && !product?.is_children) {
          products = products.filter(item => item.parent_id != product.parent_id)
        }
      }
      state.rental.products = products
    },
    setCustomerToOrder(state, customer) {
      state.order.customer = customer
    },
    setCustomerToBudget(state, customer){
      state.budget.customer = customer
    },
    setPrescriberToOrder(state, prescriber) {
      state.prescriber = prescriber
    },
    setCustomerToRental(state, customer) {
      state.rental.customer = customer
    },
    setPaymentToOrder(state, payment) {
      const payments = state.order.payments
      // const hasItem = payments.find(item => item.id == payment.id);
      // if (!hasItem) {
      payments.push(payment)
      state.order.payments = payments
      // }

    },
    setPaymentToBudget(state, payment) {
      const payments = state.budget.payments
      payments.push(payment)
      state.budget.payments = payments
    },
    removePaymentToOtder(state, index) {
      // const payments = state.order.payments
      // payments = payments.filter(function(item){
      //   if (item.payment_code == payment.payment_code) {
      //     return false
      //   }
      //    return true
      // });

      // state.order.payments = payments
      state.order.payments.splice(index, 1)
    },
    removePaymentToBudget(state,index){
      state.budget.payments.splice(index, 1);
    },
    removeAllPaymentToOtder(state, payment) {
      state.order.payments.length = 0
    },
    removeAllPaymentsToBudget(state,payment){
      state.budget.payments.length = 0
    },
    deleteOrder(state) {
      if (!state.order.service_order_equipment_id) {
        state.order.customer = {
          id: null,
          sankhya_id: null,
          name: '',
          email: '',
          document: '',
          phone: '',
          type: 'F',
          zip_code: '',
          address: '',
          number_address: '',
          district: '',
          city: '',
          uf: '',
          state: '',
          complement_address: '',
          ibge: '',
          ie: '',
          is_dealer: false,
          is_prescribers: null,
        }
      }
      state.order.prescriber_id = null
      state.order.payments = []
      if (!state.order.service_order_equipment_id) {
        state.order.products = []
      } else {
        state.order.products = state.order.products.filter(product => product?.is_service_order)
      }
      state.order.total_amount = 0
      state.order.subtotal = 0
      state.order.shipping_amount = 0
      state.order.shipping_cost = 0
      state.order.discount_amount = 0
      state.order.obs = null
      state.order.obsInternal = null
      state.order.is_delivery = false
      state.order.shipping_method = null
      state.order.shipping_estimate_days = null
      state.order.intelipost_response = null
      state.spotDiscountAmount = 0
      state.order.indication = null
      state.order.rental_id = null
      if (!state.order.service_order_equipment_id) {
        state.order.service_order_equipment_id = null
        state.order.service_order_budget_id = null
      }
      if(state.prescriber.id){
        state.prescriber.id = null
        state.prescriber.name = null
      }
    },
    setOrder(state, order) {
      state.order = order
    },
    setTypeServiceOrderId(state, typeId) {
      // state.typeServiceOrderId = typeId
      saveStorage('typeServiceOrderId', typeId)
    },
    deleteServiceOrder(state) {
      state.serviceOrder.customer = {
        id: null,
        sankhya_id: null,
        name: '',
        email: '',
        document: '',
        phone: '',
        type: 'F',
        zip_code: '',
        address: '',
        number_address: '',
        district: '',
        city: '',
        uf: '',
        state: '',
        complement_address: '',
        ibge: '',
        ie: '',
        is_dealer: false,
        is_prescribers: null,
      }
      state.serviceOrder.prescriber_id = null
      state.serviceOrder.equipments = [],
      state.serviceOrder.total_amount = 0
      state.serviceOrder.subtotal = 0
      state.serviceOrder.shipping_amount = 0
      state.serviceOrder.shipping_cost = 0
      state.serviceOrder.discount_amount = 0
      state.serviceOrder.obs = null
      state.serviceOrder.shipping_method = null
      state.serviceOrder.shipping_estimate_days = null
      state.serviceOrder.intelipost_response = null
      state.serviceOrder.indication = null
    },
    deleteBudget(state) {
      state.budget.customer = {
        id: null,
        sankhya_id: null,
        name: '',
        email: '',
        document: '',
        phone: '',
        type: 'F',
        zip_code: '',
        address: '',
        number_address: '',
        district: '',
        city: '',
        uf: '',
        state: '',
        complement_address: '',
        ibge: '',
        ie: '',
        is_dealer: false,
        is_prescribers: null,
      }
      state.budget.prescriber_id = null
      state.budget.payments = []
      state.budget.products = []
      state.budget.total_amount = 0
      state.budget.subtotal = 0
      state.budget.shipping_amount = 0
      state.budget.shipping_cost = 0
      state.budget.discount_amount = 0
      state.budget.is_delivery = false
      state.budget.shipping_method = null
      state.budget.shipping_estimate_days = null
      state.budget.intelipost_response = null
      state.spotDiscountAmount = 0
      state.budget.indication = null
      if(state.prescriber.id){
        state.prescriber.id = null
        state.prescriber.name = null
      }
    },
    setServiceOrder(state, serviceOrder) {
      state.serviceOrder = serviceOrder
    },
    setServiceOrderEquipment(state, serviceOrderEquipment) {
      state.serviceOrderEquipment = serviceOrderEquipment
    },
    deleteRental(state) {
      state.rental.customer = {
        id: null,
        sankhya_id: null,
        name: '',
        email: '',
        document: '',
        phone: '',
        type: 'F',
        zip_code: '',
        address: '',
        number_address: '',
        district: '',
        city: '',
        uf: '',
        state: '',
        complement_address: '',
        ibge: '',
        ie: '',
        is_prescribers: null,
      }
      state.rental.prescriber_id = null
      state.rental.payments = []
      state.rental.products = [],
      state.rental.total_amount = 0
      state.rental.subtotal = 0
      state.rental.shipping_amount = 0
      state.rental.shipping_cost = 0
      state.rental.discount_amount = 0
      state.rental.obs = null
      state.rental.is_delivery = false
      state.rental.shipping_method = null
      state.rental.shipping_estimate_days = null
      state.rental.intelipost_response = null
      state.rental.indication = null
    },
    setRental(state, rental) {
      state.rental = rental
    },
    setRentalIdToOrder(state, rentalId) {
      state.order.rental_id = rentalId
    },
    setIndicationIdToOrder(state, indicationId){
      state.order.indication = indicationId
    }
  },
  actions: {
    async confirmAction({ state }, message) {
      if (!state.customConfirm) {
        throw new Error('CustomConfirm component is not initialized');
      }
      try {
        const result = await state.customConfirm.open(message);
        return result;
      } catch (error) {
        console.warn('Modal foi cancelado:', error);
        return false;
      }
    },
    playSoundConfirmWarn({ state }) {
      const audio = new Audio(state.soundPath);
      audio.play().catch(error => {
        console.error('Erro ao reproduzir o som:', error);
      });
    },
    toggleDropdownContents({ commit }, ids) {
      ids.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          const collapseTrigger = document.querySelector(`[data-bs-target="#${id}"]`);
          if (collapseTrigger instanceof HTMLElement) {
            collapseTrigger.click();
          }
        }
      });
      commit('toggleContentsVisibility');
    },
    setModalMessage({ commit }, { message, type, route, reload }) {
      commit('setMessage', { message, type, route });
      commit('setReloadOnModalClose', reload || false);
    },
    closeMessage({ commit, state }) {
      commit('resetMessage');
      if (state.modalHistory.length > 0) {
        const previousModal = state.modalHistory[state.modalHistory.length - 1];
        const modalElement = document.getElementById(previousModal);
        if (modalElement) {
          const bootstrapModal = new Modal(modalElement);
          bootstrapModal.show();
        }
        commit('removeFromModalHistory');
      }
    },
    addModalToHistory({ commit }, modalId) {
      commit('addToModalHistory', modalId);
    },
    getUser(context) {
      if (context.state.user.name != '') {
        context.commit('setUser', getStorage('user'))
      }

      return context.state.user
    },
    // exemplo para chamar
    // this.$store.dispatch('teste', data).then(() => {console.log('terminou')})
    async login(context, dataJson) {
      // return new Promise ((resolve) => {
      //   console.log("login");
      //     fetch("/login", {
      //         method: 'POST',
      //         redirect: 'follow',
      //         headers: {"Content-Type": "application/json"},
      //         body: dataJson
      //     }).then(response => {
      //       console.log(response);
      //       console.log(response.status);
      //       console.log(response.access_token);
      //       if (response.status == 200) {
      //         context.commit('setToken', response.access_token);
      //       }

      //     });
      //   resolve()
      // })
      const url = process.env.VUE_APP_URL_API + "/login"
      const req = await fetch(url, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: dataJson
      });

      const res = await req.json();

      if (req.status == 200) {
        context.commit('setToken', res.access_token);
        context.commit('setExpiresIn', res.expires_in - 60);
        context.commit('setLoggedIn', true);

        return true;
      }

      return false;
    },

    async getInfoUserLogged(context) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + context.state.token);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders
      };
      const url = process.env.VUE_APP_URL_API + "/user"
      const req = await fetch(url, requestOptions);

      const res = await req.json();

      if (req.status == 200) {
        const user = {
          name: res.name,
          email: res.email,
          seller_id: res.seller_id,
          view_only_orders: res.view_only_orders,
          ignore_barcode: res.ignore_barcode,
          permissions: res.permissions,
          skip_stock_reservation: res.skip_stock_reservation
        }
        context.commit('setUser', user);
        context.commit('setTypeOrders', res.type_orders)
        context.commit('setTypeRentals', res.type_rentals)
        context.commit('setDeadlines', res.deadlines)
        context.commit('setEnterprises', res.enterprises)
        context.commit('setPayments', res.payments)
        context.commit('setTypeServiceOrders', res.type_service_orders)
        context.commit('setApprovedServices', res.approved_services)

        await context.getters.getEnterprises
        return true
      }

      return false;
    },
    setInfoUserLogged(context, user) {
      context.commit('setUser', user);
    },
    async logout(context) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + context.state.token);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders
      };
      const url = process.env.VUE_APP_URL_API + "/logout"
      const req = await fetch(url, requestOptions);
      const currentRoute = router.currentRoute.value.fullPath;
      if (req.status == 401) {
        clearStorage()
        context.commit('setUser', null);
        context.commit('setToken', null);
        context.commit('setLoggedIn', false);

        router.push({ 
          name: 'login', 
          query: { redirect: currentRoute } 
        })
      }

    },
    async refreshToken(context) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + context.state.token);
    
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
      };
      const url = process.env.VUE_APP_URL_API + "/user/refresh";
      const req = await fetch(url, requestOptions);
    
      if (req.status === 200) {
        const res = await req.json();
        context.commit('setToken', res.access_token);
        context.commit('setExpiresIn', res.expires_in - 60);
        context.commit('setLoggedIn', true);
        return true;
      } else if (req.status === 401) {
        clearStorage('token');
        clearStorage('user');
        clearStorage('loggedIn');
        context.commit('setLoggedIn', false);
    
        const currentRoute = router.currentRoute.value.fullPath;
    
        const redirect = currentRoute.includes('redirect=')
          ? currentRoute.split('redirect=')[1].split('&')[0]
          : currentRoute;
    
        router.push({
          name: 'login',
          query: redirect ? { redirect: redirect } : {},
        });
      }
    
      return false;
    },
    async sendRequest(context, { url, requestOptions }) {
      // if (requestOptions.headers.has('Authorization')) {
      //   if (requestOptions.headers.has('Authorization') != context.getters.getToken) {
      //     requestOptions.headers.delete('Authorization')
      //     requestOptions.headers.append("Authorization", "Bearer " + context.getters.getToken);
      //   }
      // }
      url = process.env.VUE_APP_URL_API + url
      let req = await fetch(url, requestOptions)
      if (req.status == 401) {
        const reqRefresh = await context.dispatch('refreshToken');
        if (!reqRefresh) {
          // implementar a queda de sessão
          return false
        }

        if (requestOptions.headers.has('Authorization')) {
          requestOptions.headers.delete('Authorization')
          requestOptions.headers.append("Authorization", "Bearer " + context.getters.getToken);
        }
        req = await fetch(url, requestOptions)
      }

      let res = null;

      try {
        res = await req.json()
      } catch (error) {
        console.log("Error request JSON", error)
      }

      return { response: res, request: req }
    },
    async sendRequestJava(context, { url, requestOptions }) {
      url = process.env.VUE_APP_URL_API_JAVA + url;
      let req = await fetch(url, requestOptions);

      if (req.status == 401) {
        const reqRefresh = await context.dispatch('refreshToken');
        if (!reqRefresh) {
          return false;
        }
        if (requestOptions.headers.has('Authorization')) {
          requestOptions.headers.delete('Authorization');
          requestOptions.headers.append("Authorization", "Bearer " + context.getters.getToken);
        }
        req = await fetch(url, requestOptions);
      }
      let res = null;
      try {
        const contentType = req.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          res = await req.json();
        } else {
          res = await req.text();
        }
      } catch (error) {
        console.log("Error processing request:", error);
      }
      return { response: res, request: req };
    },
    async sendRequestDownload(context, { url, requestOptions }) {
      url = process.env.VUE_APP_URL_API + url
      let req = await fetch(url, requestOptions)
      if (req.status == 401) {
        const reqRefresh = await context.dispatch('refreshToken');
        if (reqRefresh.status == 401) {
          // implementar a queda de sessão
          return false
        }
        if (requestOptions.headers.has('Authorization')) {
          requestOptions.headers.delete('Authorization')
          requestOptions.headers.append("Authorization", "Bearer " + context.getters.getToken);
        }
        req = await fetch(url, requestOptions)
      }

      const res = await req.blob();

      return { response: res, request: req }
    },
    getRequestOptions(context, { addAuth, method, dataJson, contentType = "application/json" }) {
      const myHeaders = new Headers();
      if (contentType) {
        myHeaders.append("Content-Type", contentType);
      }
      myHeaders.append("Enterprise-Id", context.getters.getEnterpriseIdForRequest)
      if (addAuth) {
        myHeaders.append("Authorization", "Bearer " + context.getters.getToken);
      }

      const requestOptions = {
        method: method,
        headers: myHeaders,
        body: null
      };

      if (dataJson) {
        requestOptions.body = dataJson;
      }
      else {
        delete requestOptions.body
      }

      return requestOptions
    },
    setCurrentTypeOrder(context) {
      context.commit('setTypeOrderId', context.state.typeOrderId)
      const typeOrderId = context.state.typeOrderId;
      const typeOrders = context.state.typeOrders;
      let typeOrder = typeOrders.find(element => element.id == typeOrderId);
      if (typeof typeOrder === undefined) {
        typeOrder = null
      }
      context.commit('setTypeOrder', typeOrder)
    },
    setCurrentEntreprise(context) {
      const enterpriseId = context.state.enterpriseId;
      const enterprises = context.getters.getEnterprises
      const enterprise = enterprises.find(item => item.id == enterpriseId);

      context.commit('setEnterprise', enterprise)
    },
    setCurrentTypeRental(context) {
      context.commit('setTypeRentalId', context.state.typeRentalId)
      const typeRentalId = context.state.typeRentalId;
      const typeRentals = context.state.typeRentals;
      let typeRental = typeRentals.find(element => element.id == typeRentalId);
      if (typeof typeRental === undefined) {
        typeRental = null
      }
      context.commit('setTypeRental', typeRental)
    },
    async getAddressByZipcode(context, { zipcode }) {
      try {
        const req = await fetch("https://viacep.com.br/ws/" + zipcode + "/json/");
        if (req.status == 200) {
          const res = await req.json();
          if (res.erro) {
            return false
          }
          else {
            return res
          }
        }
      } catch (error) {
        return false
      }
    },
    setCurrentTypeServiceOrder(context) {
      // context.commit('setTypeServiceOrderId', context.state.typeOrderId)
      // const typeOrderId = context.state.typeOrderId;
      // const typeOrders = context.state.typeOrders;
      // let typeOrder = typeOrders.find(element => element.id == typeOrderId);
      // if (typeof typeOrder === undefined) {
      //   typeOrder = null
      // }
      // context.commit('setTypeServiceOrder', typeOrder)
    },
  },
  modules: {
  }
})
